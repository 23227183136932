import React, { FC, useMemo, ReactElement, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, get, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { MenuItemProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from '@mui/icons-material/Replay';

import SensorConfigurationMapper from './SensorConfigurationMapper';
import InitialConfigFormFields from '../models/InitialConfigFormFields';
import FormPanel from '../FormPanel';
import ConnectForm from '../../../common/reactHookForm/ConnectForm';
import { DeviceIconDto, FormulaValidationProps } from '@thingslog/repositories';

const SensorConfiguration: FC<SensorConfigurationProps> = ({
  icons,
  portsFieldArray,
  formulaToValidateChangeCallback,
}: SensorConfigurationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ConnectForm<InitialConfigFormFields>>
        {({
          control,
          formState: { defaultValues, errors },
          trigger,
        }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
          const iconsOptions: MenuItemProps[] = useMemo(() => {
            const iconItems = icons.map((icon: DeviceIconDto) => ({
              value: icon.id,
              key: icon.id,
              children: <img style={{ height: 25 }} src={icon.icon} alt={icon.name} />,
            }));

            const noIconItem = {
              value: '',
              key: 'noIcon',
              children: <span className="text-gray-400">{t('icon_no_icon')}</span>,
            };

            return [...iconItems, noIconItem];
          }, [icons]);

          return (
            <>
              {portsFieldArray.fields.map(
                (port: FieldArrayWithId<InitialConfigFormFields, 'ports', 'id'>, index: number) => {
                  return (
                    <FormPanel
                      key={port.id}
                      items={[
                        {
                          type: 'select',
                          tooltip: t('config_tooltip_select_sensor_icon'),
                          controllerProps: {
                            control: control,
                            name: `ports.${index}.sensor.iconId`,
                          },
                          selectProps: {
                            MenuProps: { PaperProps: { sx: { height: 300 } } },
                            size: 'small',
                            variant: 'standard',
                            disableUnderline: true,
                            displayEmpty: true,
                          },
                          menuItems: iconsOptions,
                        },
                        {
                          type: 'textField',
                          tooltip: t('config_tooltip_sensor_name'),
                          controllerProps: {
                            control: control,
                            name: `ports.${index}.sensor.name`,
                            rules: {
                              required: true,
                            },
                          },
                          textFieldProps: {
                            inputProps: { className: 'font-bold' },
                            size: 'small',
                            variant: 'standard',
                            error: !!errors.ports?.[index]?.sensor?.name,
                          },
                        },
                        {
                          type: 'switch',
                          tooltip: t('config_tooltip_enable_disable_sensor'),
                          controllerProps: {
                            control: control,
                            name: `ports.${index}.enabled`,
                          },
                          switchProps: { className: 'ml-auto' },
                        },
                        {
                          type: 'iconButton',
                          tooltip: t('config_tooltip_reset_sensor'),
                          onClick: (): void => {
                            portsFieldArray.update(index, get(defaultValues, `ports.${index}`));
                            trigger();
                          },
                          icon: <ReplayIcon />,
                        },
                        {
                          type: 'iconButton',
                          tooltip: t('config_tooltip_delete_sensor'),
                          onClick: (): void => {
                            portsFieldArray.remove(index);
                          },
                          icon: <ClearIcon />,
                        },
                      ]}
                    >
                      <div className="grid grid-cols-5 gap-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-1 items-end">
                        <SensorConfigurationMapper
                          sensorType={port.sensor['@type']}
                          index={index}
                          formulaToValidateChangeCallback={formulaToValidateChangeCallback}
                        />
                      </div>
                    </FormPanel>
                  );
                }
              )}
            </>
          );
        }}
      </ConnectForm>
    </>
  );
};

interface SensorConfigurationProps {
  icons: DeviceIconDto[];
  portsFieldArray: UseFieldArrayReturn<InitialConfigFormFields, 'ports', 'id'>;
  formulaToValidateChangeCallback?: (props: FormulaValidationProps) => void;
}

export default SensorConfiguration;
