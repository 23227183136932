import Axios from './clients/Axios/Axios';

function postLinkDevice(deviceNumber, companyId, cb, ecb) {
  return Axios.post(
    `/api/link_devices/${deviceNumber}/${companyId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function deleteLinkDevice(deviceNumber, companyId, removeForSubAccounts, cb, ecb) {
  return Axios.delete(
    `/api/link_devices/${deviceNumber}/${companyId}`,
    { params: { removeForSubAccounts: removeForSubAccounts } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const LinkDevicesClient = {
  postLinkDevice,
  deleteLinkDevice,
};

export default LinkDevicesClient;
