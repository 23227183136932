import { AxiosError } from 'axios';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries';
import {
  ErrorResponse,
  LicenseDto,
  LicenseType,
  UnlinkDeviceResult,
} from '@thingslog/repositories';
import { LicenseWarningBox, useToast } from '@thingslog/ui-components';
import { ReduxState } from '../../reducers';
import JwtValidator from '../../common/JwtValidator';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import { GaEventCategory } from '../../common/GaEventCategory';
import { GaEventAction } from '../../common/GaEventAction';
import Header from '../../components/header';

import {
  licenseQueryClient,
  linkDevicesQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';

const LinkDevicesV2: FC<LinkDevicesProps> = () => {
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [deviceNumber, setDeviceNumber] = useState<string | null>(null);
  const [isLinkingDisabled, setIsLinkingDisabled] = useState<boolean>(true);
  const [unlinkDevicesForSubAccounts, setUnlinkDevicesForSubAccounts] = useState<boolean>(false);

  const { t } = useTranslation();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { decodedToken } = useMemo(() => new JwtValidator(), []);
  const { useLinkDevice, useUnlinkDevice } = useMemo(() => linkDevicesQueryClient, []);
  const { useLicenses, useDeviceLimits } = useMemo(() => licenseQueryClient, []);

  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const companyIdToken = useMemo(() => decodedToken?.companyId, [decodedToken]);

  const { mutate: linkDevice, isLoading: isLinking } = useLinkDevice({
    onMutate: () => {
      GoogleAnalyticsService.triggerEvent(
        GaEventCategory.LINK_DEVICE_PAGE,
        GaEventAction.LINK_DEVICE_PAGE_LINK_DEVICE
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      queryClient.invalidateQueries([QueryKeys.GetDeviceLimits]);

      toast({
        type: 'success',
        message: t('link_device_success_message'),
        duration: 5000,
      });
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      const errorMessage = error.response?.data.message || t('link_device_default_error');

      toast({
        type: 'error',
        message: errorMessage,
        duration: 5000,
      });
    },
  });

  const { mutate: unlinkDevice, isLoading: isUnlinking } = useUnlinkDevice({
    onMutate: () => {
      GoogleAnalyticsService.triggerEvent(
        GaEventCategory.LINK_DEVICE_PAGE,
        GaEventAction.LINK_DEVICE_PAGE_UNLINK_DEVICE
      );
    },
    onSuccess: (result: UnlinkDeviceResult) => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      queryClient.invalidateQueries([QueryKeys.GetDeviceLimits]);
      const companies =
        result.updatedCompanies.length > 0 ? result.updatedCompanies.join(', ') : t('none');
      toast({
        type: 'success',
        message: t('unlink_device_success_message') + companies,
        duration: 5000,
      });
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      const errorMessage = error.response?.data.message || t('link_device_default_error');

      toast({
        type: 'error',
        message: errorMessage,
        duration: 5000,
      });
    },
  });

  const { data: licenses, isFetched: areLicensesFetched } = useLicenses(
    companyId,
    undefined,
    undefined,
    {
      enabled: !!companyId,
    }
  );

  const { data: deviceLimits, isFetched: areDeviceLimitsFetched } = useDeviceLimits(companyId!, {
    enabled:
      companyId !== null &&
      licenses !== undefined &&
      licenses.length > 0 &&
      licenses.some((license: LicenseDto) => license['@type'] === LicenseType.PER_DEVICE),
  });

  useEffect(() => {
    setCompanyId(companyIdRedux || Number(companyIdToken));
  }, [companyIdRedux, companyIdToken]);

  useEffect(() => {
    let isLinkingDisabled = false;

    const isLicenseTypePerDevice = licenses?.some(
      (license: LicenseDto) => license['@type'] === LicenseType.PER_DEVICE
    );

    if (isLinking) isLinkingDisabled = true;

    if (!areLicensesFetched) isLinkingDisabled = true;

    if (isLicenseTypePerDevice && !areDeviceLimitsFetched) isLinkingDisabled = true;

    if (deviceLimits?.hasReachedLimit) isLinkingDisabled = true;

    setIsLinkingDisabled(isLinkingDisabled);
  }, [isLinking, licenses, areLicensesFetched, areDeviceLimitsFetched, deviceLimits]);

  return (
    <Header>
      <div className="flex flex-col space-y-9 items-center">
        <div className="relative w-[400px] max-sm:w-full flex-col p-5 bg-neutral-100 border border-solid border-gray-200 rounded">
          {(isLinking || isUnlinking) && (
            <CircularProgress className="absolute top-5 right-5" size={20} />
          )}
          <p>{t('link_device_link_device_to_my_account')}</p>
          <p className="font-bold">{t('link_device_device_number')}</p>
          <TextField
            className="mb-4 bg-white"
            fullWidth
            size="small"
            label={t('number')}
            value={deviceNumber}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setDeviceNumber(event.target.value);
            }}
          />
          <div className="flex gap-4 justify-center">
            <Button
              disabled={isLinkingDisabled}
              onClick={(): void =>
                linkDevice({ companyId: companyId!, deviceNumber: deviceNumber! })
              }
            >
              {t('link_link_device')}
            </Button>
            <Button
              onClick={(): void =>
                unlinkDevice({
                  companyId: companyId!,
                  deviceNumber: deviceNumber!,
                  removeForSubAccounts: unlinkDevicesForSubAccounts,
                })
              }
            >
              {t('link_device_unlink_device')}
            </Button>
          </div>
          <Tooltip title={t('link_device_unlink_devices_for_sub_accounts_tooltip') || ''}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={unlinkDevicesForSubAccounts}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    setUnlinkDevicesForSubAccounts(event.target.checked)
                  }
                />
              }
              label={t('link_device_unlink_devices_for_sub_accounts')}
            />
          </Tooltip>
          {deviceLimits?.['@type'] === 'DEVICE_LIMITS' && (
            <div className="font-bold">
              {t('link_device_devices_counts', {
                linked_devices_count: deviceLimits.linkedDevices,
                allowed_devices_count: deviceLimits.maxDataLoggers,
              })}
            </div>
          )}
        </div>
        {deviceLimits?.hasReachedLimit && (
          <LicenseWarningBox
            warningStatus={{
              devicesCountLimitReached: deviceLimits.hasReachedLimit,
              noActiveLicense: false,
              noValidLicense: false,
            }}
            translation={{
              accessOnHoldTitle: t('license_warning_access_hold'),
              accessOnHoldDescription: t('license_warning_access_hold_description'),
              noValidLicenseTitle: t('license_warning_no_valid_license'),
              noValidLicenseDescription: t('license_warning_no_valid_license_description'),
              deviceLimitReachedTitle: t('license_warning_devices_count_limit_reached'),
              deviceLimitReachedDescription: t(
                'license_warning_devices_count_limit_reached_description'
              ),
              defaultGenericTitle: t('license_warning_generic_title'),
              defaultGenericDescription: t('license_warning_generic_description'),
            }}
          />
        )}
      </div>
    </Header>
  );
};

interface LinkDevicesProps {}

export default LinkDevicesV2;
