import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';

import _ from 'lodash';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FieldArrayWithId,
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import FormPanel from './FormPanel';
import DeviceDetails from './deviceDetails/DeviceDetails';

import InitialConfigConverter from './helpers/InitialConfigConverter';
import SensorConfiguration from './sensorConfig/SensorConfiguration';
import NetworkConfigurationMapper from './deviceNetworkConfig/NetworkConfigurationMapper';
import Header from '../../components/header';
import {
  devicesQueryClient,
  formulaValidatorQueryClient,
  iconsQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { ReduxState } from '../../reducers';
import {
  DeviceCreateUpdateBody,
  DeviceInitialConfig,
  FormulaValidationProps,
  FormulaValidationResult,
  PortRegistry,
  PortType,
  SensorRegistry,
  SensorType,
} from '@thingslog/repositories';
import { QueryKeys } from '@thingslog/queries';
import { useModal, useToast } from '@thingslog/ui-components';
import AddSensorPanel from './sensorConfig/AddSensorPanel';
import InitialConfigFormFields from './models/InitialConfigFormFields';

const SensorsAndNetworkConfig: FC<SensorsAndNetworkConfigProps> = () => {
  // #region State
  const [isFormulaValidateDebouncePending, setIsFormulaValidateDebouncePending] =
    useState<boolean>(false);
  // #endregion

  // #region Hooks
  const { toast } = useToast();
  const { t } = useTranslation();
  const { deviceNumber } = useParams();
  const methods = useForm<InitialConfigFormFields>({ mode: 'onChange' });
  const portsFieldArray = useFieldArray({
    control: methods.control,
    name: 'ports',
  });
  const { modal, closeModal } = useModal();
  const reduxCompanyId = useSelector((state: ReduxState) => state.company.id);
  const queryClient = useQueryClient();
  // #endregion

  //Todo: add utility rates per sensor

  // #region Queries & Mutations
  const { useDeviceInitialConfig, useUpdateDeviceInitialConfig } = useMemo(
    () => initialConfigQueryClient,
    []
  );
  const { useValidateFormula } = useMemo(() => formulaValidatorQueryClient, []);
  const { useDeviceData, useUpdateDevice } = useMemo(() => devicesQueryClient, []);
  const { useGetIcons } = useMemo(() => iconsQueryClient, []);

  const deviceInitialConfigQuery = useDeviceInitialConfig(deviceNumber!, {
    enabled: deviceNumber !== undefined,
    refetchOnWindowFocus: false,
    onSuccess: (data: DeviceInitialConfig) => {
      const formFields = InitialConfigConverter.portsConfigToFormFields(data);
      methods.reset(formFields);
    },
  });

  const deviceInitialConfigMutation = useUpdateDeviceInitialConfig({
    onSuccess: () => {
      toast({ type: 'success', message: t('sensors_and_network_save_success') });
      queryClient.invalidateQueries([QueryKeys.DeviceInitialConfig]);
    },
    onError: (error: AxiosError) =>
      toast({
        type: 'error',
        message: error.response?.data.message || t('sensors_and_network_save_error'),
      }),
  });

  const getDeviceQuery = useDeviceData(deviceNumber!, {
    enabled: !!deviceNumber,
  });

  const updateDeviceMutation = useUpdateDevice({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData, deviceNumber]);
    },
  });

  const deviceIconQuery = useGetIcons(deviceNumber!, null, reduxCompanyId, {
    enabled: deviceNumber !== undefined,
    refetchOnWindowFocus: false,
  });

  const { mutate: validateFormula, isLoading: isFormulaValidateLoading } = useValidateFormula({
    onMutate: () => {
      setIsFormulaValidateDebouncePending(false);
    },
    onSuccess: (
      { valid, errorMessage }: FormulaValidationResult,
      { formulaType, sensorIndex }: FormulaValidationProps
    ) => {
      const formulaPath =
        formulaType === 'port'
          ? (`ports.${sensorIndex}.formula` as const)
          : (`ports.${sensorIndex}.sensor.formula` as const);

      if (valid) {
        methods.clearErrors(formulaPath);
      } else {
        methods.setError(formulaPath, {
          message: errorMessage || t('sensors_and_network_formula_validation_default_error)'),
        });
      }
    },
    onError: () => {
      toast({
        type: 'error',
        message: t('sensors_and_network_formula_validation_default_error'),
      });
    },
  });
  // #endregion

  // #region Callbacks
  const debouncedValidateFormula = useCallback(
    _.debounce((formulaValidationProps: FormulaValidationProps) => {
      validateFormula(formulaValidationProps);
    }, 600),
    []
  );

  const handleFormulaToValidateChangeCallback = useCallback(
    (formulaValidationProps: FormulaValidationProps) => {
      setIsFormulaValidateDebouncePending(true);
      debouncedValidateFormula(formulaValidationProps);
    },
    []
  );
  // #endregion

  // #region Event Handlers
  const onSubmit = useMemo(() => {
    return (data: InitialConfigFormFields): void => {
      deviceInitialConfigMutation.mutate({
        deviceNumber: deviceNumber!,
        body: InitialConfigConverter.formFieldsToPortsConfig(data),
      });
    };
  }, []);

  const onError = (errors: FieldErrors<InitialConfigFormFields>): void => {
    toast({
      type: 'error',
      message: t('sensors_and_network_save_error'),
    });
  };

  const handleOnDeviceUpdate = (deviceNumber: string, body: DeviceCreateUpdateBody): void => {
    updateDeviceMutation.mutate({ deviceNumber, body });
  };

  const handleSensorCreate = (
    sensorIndex: number,
    portType: PortType,
    sensorType: SensorType,
    sensorName: string
  ): void => {
    const sensor = SensorRegistry[sensorType];
    const port = PortRegistry[portType];
    if (port && sensor) {
      port.sensor = sensor;
      port.sensor.name = sensorName;
      portsFieldArray.append({ portKey: sensorIndex, ...port });
    }
  };
  // #endregion

  const openAddSensorModal = (): void => {
    modal({
      title: t('sensors_and_network_config_add_sensor'),
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <AddSensorPanel
            occupiedSensorIndexesProps={portsFieldArray.fields.map(
              (port: FieldArrayWithId<InitialConfigFormFields, 'ports', 'id'>) => port.portKey
            )}
            onSubmit={handleSensorCreate}
            onClose={(): void => closeModal()}
            deviceModel={deviceInitialConfigQuery?.data?.['@type'] || null}
          />
        </div>
      ),
    });
  };

  // #region Render
  return (
    <Header>
      {deviceInitialConfigQuery.data &&
        !deviceInitialConfigQuery.isFetching &&
        getDeviceQuery.data &&
        deviceIconQuery.data && (
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-10"
              onSubmit={methods.handleSubmit(onSubmit, onError)}
            >
              <div className="w-full flex justify-end gap-5 sticky top-16 z-20 -mb-7">
                <Button
                  className="sticky"
                  type="submit"
                  variant="contained"
                  disabled={
                    !methods.formState.isValid ||
                    Object.keys(methods.formState.errors).length > 0 ||
                    isFormulaValidateDebouncePending ||
                    isFormulaValidateLoading
                  }
                  startIcon={
                    (isFormulaValidateDebouncePending || isFormulaValidateLoading) && (
                      <CircularProgress size={20} />
                    )
                  }
                >
                  {t('button_save')}
                </Button>
                <Tooltip title={t('config_tooltip_reset_all_fields') || ''}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={(): void =>
                      methods.reset(
                        InitialConfigConverter.portsConfigToFormFields(
                          deviceInitialConfigQuery.data
                        )
                      )
                    }
                  >
                    {t('sensors_and_network_config_reset_btn')}
                  </Button>
                </Tooltip>
                <Button
                  type="button"
                  variant="contained"
                  onClick={openAddSensorModal}
                  disabled={!deviceInitialConfigQuery.data.canAddRemoveSensors}
                >
                  {t('sensors_and_network_config_add_sensor')}
                </Button>
              </div>
              <div className="grid grid-cols-3 gap-10">
                <FormPanel
                  className="col-span-1 max-md:col-span-3"
                  title={t('sensors_and_network_config_device_details')}
                >
                  <DeviceDetails
                    device={getDeviceQuery.data}
                    deviceInitialConfig={deviceInitialConfigQuery.data}
                    icons={deviceIconQuery.data?.icons || []}
                    onDeviceUpdate={handleOnDeviceUpdate}
                  />
                </FormPanel>
                <FormPanel
                  className="col-span-2 max-md:col-span-3"
                  title={t('sensors_and_network_config_network_config')}
                >
                  <NetworkConfigurationMapper deviceType={deviceInitialConfigQuery.data['@type']} />
                </FormPanel>
              </div>
              <SensorConfiguration
                icons={deviceIconQuery.data ? deviceIconQuery.data.icons : []}
                portsFieldArray={portsFieldArray}
                formulaToValidateChangeCallback={handleFormulaToValidateChangeCallback}
              />
            </form>
          </FormProvider>
        )}
    </Header>
  );
  // #region
};

interface SensorsAndNetworkConfigProps {}

export default SensorsAndNetworkConfig;
