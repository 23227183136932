import * as actions from './../../actions/index';

import {
  Device,
  DeviceCreateUpdateBody,
  DeviceFilterAttributes,
  Pagination,
} from '@thingslog/repositories';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import Button from '@mui/material/Button';
import { GaEventAction } from '../../common/GaEventAction';
import { GaEventCategory } from '../../common/GaEventCategory';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import InventoryModal from './InventoryModal';
import InventoryTable from './InventoryTable';
import { ReduxState } from '../../reducers';
import {
  devicesQueryClient,
  linkDevicesQueryClient,
  iconsQueryClient,
  deviceModelsQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  GridColumnVisibilityModel,
  GridRowId,
  GridToolbar,
  useModal,
  useToast,
} from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import JwtValidator from '../../common/JwtValidator';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmDeleteOrUnlinkModal from './ConfirmDeleteUnlinkDevice';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { CreateOrUpdateDeviceProps } from './models/CreateOrUpdateDeviceProps';

const Inventory: FC<InventoryProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modal, closeModal } = useModal();
  const { t } = useTranslation();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { useDevicesData, useDeleteDevice, useCreateDevice, useUpdateDevice } = useMemo(
    () => devicesQueryClient,
    []
  );
  const { useGetIcons } = useMemo(() => iconsQueryClient, []);
  const { useUnlinkDevice } = useMemo(() => linkDevicesQueryClient, []);
  const { useDeviceModelsData } = useMemo(() => deviceModelsQueryClient, []);
  const { hasRole } = useMemo(() => new JwtValidator(), []);

  const [attributes, setAttributes] = useState<DeviceFilterAttributes | null>(null);
  const [devices, setDevices] = useState<Device[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [page, setPage] = useState(0);
  const [queryAttributes, setQueryAttributes] = useState<DeviceFilterAttributes | null>(null);
  const [queryFilterValue, setQueryFilterValue] = useState<string | null>(null);
  const [size, setSize] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    createdBy: !!hasRole('ROLE_SUPER_ADMIN'),
  });
  const [selectedDeviceToEdit, setSelectedDeviceToEdit] = useState<string | null>(null);
  const [createOrUpdateDeviceData, setCreateOrUpdateDeviceData] =
    useState<CreateOrUpdateDeviceProps | null>(null);

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const selectedDevice = useSelector((state: ReduxState) => state.dev.devices);

  useEffect(() => {
    const timeoutFilterChange = setTimeout(() => setQueryFilterValue(filterValue), 1000);
    return (): void => clearTimeout(timeoutFilterChange);
  }, [filterValue]);

  useEffect(() => {
    const timeoutAttributesChange = setTimeout(() => setQueryAttributes(attributes), 1000);
    return (): void => clearTimeout(timeoutAttributesChange);
  }, [attributes]);

  const getDevicesQuery = useDevicesData(companyId, page, size, queryAttributes, queryFilterValue, {
    onSuccess: (data: Pagination<Device>) => {
      setDevices(data.content);
      if (data.totalElements === 0 && !queryFilterValue && !queryAttributes) {
        navigate('/app/LinkDevices');
      }
    },
    onError: () => {
      toast({
        type: 'error',
        message: t('devices_table_error'),
      });
      closeModal();
    },
  });

  const devicesDeleteQuery = useDeleteDevice({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      toast({
        type: 'success',
        message: t('device_inventory_delete_device_success'),
      });
      closeModal();
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('device_inventory_delete_device_error'),
      });
    },
  });

  const unlinkDeviceQuery = useUnlinkDevice({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      toast({
        type: 'success',
        message: t('device_inventory_unlink_device_success'),
      });
      closeModal();
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('device_inventory_unlink_device_error'),
      });
    },
  });

  const createDeviceQuery = useCreateDevice({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      toast({
        type: 'success',
        message: t('device_inventory_create_device_success'),
      });
    },
    onError: (error: AxiosError) => {
      if (error.response?.data.errorCode === 'DEVICE_ALREADY_EXISTS') {
        toast({
          type: 'error',
          message: t('device_inventory_already_exists_error'),
        });
      } else {
        toast({
          type: 'error',
          message: t('device_inventory_create_device_error'),
        });
      }
    },
  });

  const updateDeviceQuery = useUpdateDevice({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
      toast({
        type: 'success',
        message: t('device_inventory_update_device_success'),
      });
      closeModal();
    },
    onError: () => {
      toast({
        type: 'error',
        message: t('device_inventory_update_device_error'),
      });
    },
  });

  const iconsQuery = useGetIcons(selectedDeviceToEdit || null, null, companyId, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const deviceModelsQuery = useDeviceModelsData();

  useEffect(() => {
    iconsQuery.refetch();
  }, [selectedDeviceToEdit]);

  useEffect(() => {
    if (createOrUpdateDeviceData && iconsQuery.data) {
      handleOpenModal(createOrUpdateDeviceData);
    }
  }, [iconsQuery.data, createOrUpdateDeviceData]);

  const handleOpenModal = (createOrUpdateDeviceData: CreateOrUpdateDeviceProps): void => {
    const modalTitle =
      createOrUpdateDeviceData.type === 'UPDATE'
        ? t('device_inventory_update_device_title', {
            number: createOrUpdateDeviceData.deviceNumber,
          })
        : t('device_inventory_add_device');
    modal({
      title: modalTitle,
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <InventoryModal
            createOrUpdateDeviceData={createOrUpdateDeviceData}
            onCreateDevice={
              createOrUpdateDeviceData.type === 'CREATE'
                ? (body: DeviceCreateUpdateBody): void => createDeviceQuery.mutate(body)
                : undefined
            }
            onUpdateDevice={
              createOrUpdateDeviceData.type === 'UPDATE'
                ? (deviceNumber: string, body: DeviceCreateUpdateBody): void => {
                    updateDeviceQuery.mutate({ deviceNumber, body });
                  }
                : undefined
            }
            iconsDropdownOptions={iconsQuery.data?.icons || []}
            deviceModelsDropdownOptions={deviceModelsQuery.data || []}
          />
        </div>
      ),
    });
  };

  const handleCreateOrUpdateDevice = (
    createOrUpdateDeviceData: CreateOrUpdateDeviceProps
  ): void => {
    setCreateOrUpdateDeviceData(createOrUpdateDeviceData);
    if (createOrUpdateDeviceData.type === 'UPDATE') {
      setSelectedDeviceToEdit(createOrUpdateDeviceData.deviceNumber);
    } else {
      setSelectedDeviceToEdit(null);
    }
  };

  const handleDeleteOrUnlinkDevice = (
    selectedDevice: string,
    companyId: number | null,
    showUnlinkBtn: boolean
  ): void => {
    const modalTitle = showUnlinkBtn
      ? t('device_inventory_modal_select_action', { number: selectedDevice })
      : t('device_inventory_modal_delete_device_title', { number: selectedDevice });
    modal({
      title: modalTitle,
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <ConfirmDeleteOrUnlinkModal
            onDeleteDevice={(): void => devicesDeleteQuery.mutate(selectedDevice)}
            showUnlinkBtn={showUnlinkBtn}
            onUnlinkDevice={
              companyId && showUnlinkBtn
                ? (): void =>
                    unlinkDeviceQuery.mutate({
                      companyId,
                      deviceNumber: selectedDevice,
                      removeForSubAccounts: true,
                    })
                : undefined
            }
          />
        </div>
      ),
    });
  };

  return (
    <Header>
      <TitleHeader title={t('device_inventory_page_header')} />
      <section className="flex justify-end flex-wrap">
        <div className="flex space-x-2 max-lg:mt-4 max-md:w-full">
          {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && (
            <Button
              variant="contained"
              className="max-md:w-full max-md:mt-4"
              onClick={(): void => handleCreateOrUpdateDevice({ type: 'CREATE' })}
            >
              {t('device_inventory_add_device')}
            </Button>
          )}
        </div>
      </section>

      <div className="my-5">
        <InventoryTable
          devices={devices}
          onDeleteOrUnlinkDevice={handleDeleteOrUnlinkDevice}
          onUpdateDevice={handleCreateOrUpdateDevice}
          companyId={companyId}
          showUnlinkBtn={hasRole('ROLE_SUPER_ADMIN') && companyId !== null}
          showDeleteIcons={hasRole('ROLE_SUPER_ADMIN')}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowCount={getDevicesQuery.data ? getDevicesQuery.data.totalElements : 0}
          pageSize={size}
          onPageChange={(page: number): void => {
            setPage(page);
          }}
          onPageSizeChange={(pageSize: number): void => {
            setSize(pageSize);
            setPage(0);
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight={true}
          page={page}
          paginationMode="server"
          filterMode="server"
          disableSelectionOnClick={false}
          checkboxSelection
          headerHeight={120}
          disableColumnMenu={true}
          disableColumnFilter={true}
          setAttributes={setAttributes}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          keepNonExistentRowsSelected={true}
          selectionModel={selectedDevice}
          onSelectionModelChange={(selectedRowId: GridRowId[]): void => {
            if (selectedRowId.length > 0) {
              const lastSelectedDevice = selectedRowId[selectedRowId.length - 1];
              dispatch(actions.devSelected([lastSelectedDevice], 'radio'));
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.INVENTORY_PAGE,
                GaEventAction.INVENTORY_PAGE_DEVICE_NUMBER_CLICK,
                String(lastSelectedDevice)
              );
            } else {
              dispatch(actions.devSelected([], 'radio'));
            }
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(columnModel: GridColumnVisibilityModel): void => {
            setColumnVisibilityModel({
              ...columnModel,
              createdBy: !!hasRole('ROLE_SUPER_ADMIN'),
            });
          }}
        />
      </div>
    </Header>
  );
};

interface InventoryProps {}

export default Inventory;
