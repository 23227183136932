import React, { ReactElement, ReactNode } from 'react';

import {
  Controller,
  FieldValues,
  Path,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';
import {
  IconButton,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';

import { cn, hash } from '@thingslog/ui-components';

const FormPanel = <T extends FieldValues>({
  className,
  title,
  children,
  items = [],
}: FormPanelProps<T>): ReactElement => {
  return (
    <div className={cn('flex flex-col rounded-lg overflow-hidden', className)}>
      <div className="flex justify-between items-center px-5 py-1 text-lg font-bold text-left bg-slate-200 w-full">
        {title && <div className="py-2">{title}</div>}
        {items.map((item: HeaderItem<T>, index: number) => {
          if (item.type === 'iconButton') {
            return (
              <Tooltip key={hash(`menuItem-${index}`)} title={item.tooltip || ''}>
                <IconButton key={hash(`menuItem-${index}`)} onClick={item.onClick}>
                  {item.icon}
                </IconButton>
              </Tooltip>
            );
          }

          return (
            <Controller
              key={hash(`menuItem-${index}`)}
              {...item.controllerProps}
              render={({ field }: UseControllerReturn<T, Path<T>>): ReactElement => {
                switch (item.type) {
                  case 'select': {
                    return (
                      <Tooltip title={item.tooltip || ''}>
                        <Select
                          {...field}
                          {...item.selectProps}
                          value={field.value || ''}
                          onChange={(event: SelectChangeEvent<unknown>): void =>
                            field.onChange(event.target.value || null)
                          }
                        >
                          {item.menuItems.map(
                            (menuItemProps: MenuItemProps): ReactElement => (
                              <MenuItem {...menuItemProps} />
                            )
                          )}
                        </Select>
                      </Tooltip>
                    );
                  }
                  case 'switch': {
                    return (
                      <Tooltip title={item.tooltip || ''}>
                        <Switch
                          key={hash(`menuItem-${index}`)}
                          {...field}
                          {...item.switchProps}
                          checked={field.value}
                        />
                      </Tooltip>
                    );
                  }
                  case 'textField': {
                    return (
                      <Tooltip title={item.tooltip || ''}>
                        <TextField
                          key={hash(`menuItem-${index}`)}
                          {...field}
                          {...item.textFieldProps}
                        />
                      </Tooltip>
                    );
                  }
                }
              }}
            />
          );
        })}
      </div>
      <div className="flex-grow p-5 rounded-b-lg border-t-0 border-b border-x border-solid border-slate-300">
        {children}
      </div>
    </div>
  );
};

interface FormPanelProps<T extends FieldValues> {
  className?: string;
  title?: string;
  children: ReactNode;
  items?: HeaderItem<T>[];
}

interface HeaderItemBase {
  type: 'select' | 'switch' | 'textField' | 'iconButton';
  tooltip?: string;
}

interface ControlledHeaderItem<T extends FieldValues> extends HeaderItemBase {
  controllerProps: UseControllerProps<T>;
}

interface HeaderTextFieldItem<T extends FieldValues> extends ControlledHeaderItem<T> {
  type: 'textField';
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>;
}

interface HeaderSelectItem<T extends FieldValues> extends ControlledHeaderItem<T> {
  type: 'select';
  selectProps?: Omit<SelectProps, 'value' | 'onChange'>;
  menuItems: MenuItemProps[];
}

interface HeaderSwitchItem<T extends FieldValues> extends ControlledHeaderItem<T> {
  type: 'switch';
  switchProps: Omit<SwitchProps, 'checked'>;
}

interface HeaderIconButtonItem extends HeaderItemBase {
  type: 'iconButton';
  onClick: () => void;
  icon: ReactNode;
}

type HeaderItem<T extends FieldValues> =
  | HeaderSelectItem<T>
  | HeaderSwitchItem<T>
  | HeaderTextFieldItem<T>
  | HeaderIconButtonItem;

export default FormPanel;
