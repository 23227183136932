import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { ChangeEvent, FC, useMemo, useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { hash } from '@thingslog/ui-components';
import {
  AnalogSensorType,
  DeviceType,
  DigitalSensorType,
  OnOffSensorType,
  PortType,
  SensorType,
} from '@thingslog/repositories';
import portTypeOptions from '../common/PortTypeOptions';
import { useTranslation } from 'react-i18next';
import { getSensorTypeOptionsBasedOnPortTypeAndDeviceModel } from '../common/SensorTypeOptions';
import { OnOffInputModbusSensorType, OnOffOutputModbusSensorType } from '@thingslog/repositories';

const AddSensorPanel: FC<AddSensorPanelProps> = ({
  occupiedSensorIndexesProps,
  deviceModel,
  onSubmit,
  onClose,
}: AddSensorPanelProps) => {
  const [sensorIndex, setSensorIndex] = useState<number | null>(null);
  const [portType, setPortType] = useState<PortType | null>(null);
  const [sensorType, setSensorType] = useState<SensorType | null>(null);
  const [sensorName, setSensorName] = useState<string | null>(null);
  const [occupiedIndexes, setOccupiedIndexes] = useState<number[]>(
    occupiedSensorIndexesProps || []
  );

  const { t } = useTranslation();

  const sensorTypeOptions:
    | Record<AnalogSensorType, string>
    | Record<DigitalSensorType, string>
    | Record<OnOffSensorType, string>
    | Record<OnOffInputModbusSensorType, string>
    | Record<OnOffOutputModbusSensorType, string>
    | null = useMemo(() => {
    return getSensorTypeOptionsBasedOnPortTypeAndDeviceModel(portType, deviceModel);
  }, [portType, deviceModel]);

  const isAppendSensorDisabled = useMemo(() => {
    return (
      sensorIndex === null ||
      portType === null ||
      sensorType === null ||
      sensorName === null ||
      occupiedIndexes.includes(sensorIndex) ||
      sensorIndex < 0
    );
  }, [sensorIndex, portType, sensorType, sensorName, occupiedIndexes]);

  const computeFirstAvailableIndex = (occupiedIndexes: number[]): number => {
    const indexSet = new Set(occupiedIndexes);
    let index = 0;
    while (indexSet.has(index)) {
      index++;
    }
    return index;
  };

  useEffect(() => {
    const firstAvailableIndex = computeFirstAvailableIndex(occupiedIndexes);
    setSensorIndex(firstAvailableIndex);
  }, []);

  const handleSubmit = (): void => {
    if (sensorIndex === null || portType === null || sensorType === null || sensorName === null) {
      return;
    }

    onSubmit(sensorIndex, portType, sensorType, sensorName);

    setOccupiedIndexes((prevIndexes: number[]) => {
      const updatedIndexes = [...prevIndexes, sensorIndex];
      return updatedIndexes;
    });
    setSensorIndex(computeFirstAvailableIndex([...occupiedIndexes, sensorIndex]));
    setPortType(null);
    setSensorType(null);
    setSensorName(null);
  };

  return (
    <section className="flex flex-col p-5 gap-3">
      <TextField
        variant="outlined"
        label={t('sensors_and_network_config_sensor_index')}
        type="number"
        size="small"
        value={sensorIndex !== null ? sensorIndex : ''}
        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
          setSensorIndex(event.target.value ? Number(event.target.value) : null)
        }
        error={sensorIndex !== null && occupiedIndexes.includes(sensorIndex)}
        helperText={
          sensorIndex !== null && occupiedIndexes.includes(sensorIndex)
            ? t('sensors_and_network_config_sensor_index_error')
            : ''
        }
      />

      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel>{t('sensor_config_port_type')}</InputLabel>
        <Select
          label={t('sensor_config_port_type')}
          variant="outlined"
          size="small"
          value={portType || ''}
          onChange={(event: SelectChangeEvent<PortType>): void =>
            setPortType(event.target.value as PortType)
          }
        >
          {Object.entries(portTypeOptions).map(([value, label]: [string, string]) => (
            <MenuItem key={hash(`portType-${value}`)} value={value}>
              {t(label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" size="small" fullWidth disabled={portType === null}>
        <InputLabel>{t('device_config_sensor_type')}</InputLabel>
        <Select
          label={t('device_config_sensor_type')}
          variant="outlined"
          size="small"
          disabled={portType === null}
          value={sensorType || ''}
          onChange={(event: SelectChangeEvent<SensorType>): void =>
            setSensorType(event.target.value as SensorType)
          }
        >
          {sensorTypeOptions !== null &&
            Object.entries(sensorTypeOptions).map(([value, label]: [string, string]) => (
              <MenuItem key={hash(`sensorType-${value}`)} value={value}>
                {t(label)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label={t('sensor_config_sensor_name')}
        variant="outlined"
        size="small"
        value={sensorName || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
          setSensorName(event.target.value || null)
        }
      />

      <div className="flex gap-2">
        <Button fullWidth size="large" variant="outlined" color="error" onClick={onClose}>
          {t('close_btn')}
        </Button>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          disabled={isAppendSensorDisabled}
          onClick={handleSubmit}
          endIcon={<AddIcon />}
        >
          {t('sensors_and_network_config_add_sensor')}
        </Button>
      </div>
    </section>
  );
};

interface AddSensorPanelProps {
  occupiedSensorIndexesProps: number[];
  deviceModel: DeviceType | null;
  onSubmit: (
    sensorIndex: number,
    portType: PortType,
    sensorType: SensorType,
    sensorName: string
  ) => void;
  onClose: () => void;
}
export default AddSensorPanel;
