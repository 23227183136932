import React from 'react';
import { FC } from 'react';
import { Link } from './Documentation';

const DocumentationSection: FC<DocumentationSectionProps> = ({
  title,
  docs,
}: DocumentationSectionProps) => {
  return (
    <section>
      <h2 className="text-xl text-left font-semibold text-black mb-4">{title}</h2>
      <ul className="space-y-2 md:space-y-4 m-0 p-0">
        {docs.map((link: Link, index: number) => (
          <li
            key={index}
            className="flex flex-wrap items-center justify-start space-x-4 border-b border-gray-200 pb-2"
          >
            <button
              className="px-4 py-2 text-white bg-cyan-500 hover:bg-cyan-600 rounded-lg shadow-md transition-transform transform hover:scale-105"
              onClick={(): Window | null => window.open(`/docs/${link.path}`, '_blank')}
            >
              View PDF
            </button>
            <div
              className="flex-1 text-left text-lg font-medium text-cyan-700 hover:text-cyan-500 break-words cursor-pointer"
              onClick={(): Window | null => window.open(`/docs/${link.path}`, '_blank')}
            >
              {link.title}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

interface DocumentationSectionProps {
  title: string;
  docs: Link[];
}

export default DocumentationSection;
