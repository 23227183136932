const i18n_en = {
  close_btn: 'Close',
  search_placeholder: 'Search',

  label_inactive: 'Inactive',
  label_invalid: 'Invalid',

  active_devices: 'Devices {{devices_len}} active',
  change_language: 'Change language',
  language_changed_message: 'Language changed',

  error_select_device_message: 'Please select device first',
  select_device: 'Select device',
  select_company_menu_message: 'Select company to view devices list',

  second: 'second',
  seconds: 'seconds',
  second_one: 'second',
  second_other: 'seconds',
  minute: 'minute',
  minutes: 'minutes',
  minute_one: 'minute',
  minute_other: 'minutes',
  hour: 'hour',
  hours: 'hours',
  hour_one: 'hour',
  hour_other: 'hours',
  day: 'day',
  days: 'days',
  day_one: 'day',
  day_other: 'days',
  start_date: 'Start Date',
  end_date: 'End Date',
  from_date: 'From Date',
  to_date: 'To Date',
  avg_from_date: 'Average From Date',
  avg_to_date: 'Average To Date',
  period: 'Period',
  description: 'Description',
  date: 'Date',
  number: 'Number',
  sensor: 'Sensor',
  apply: 'Apply',
  update: 'Update',
  create: 'Create',
  delete: 'Delete',
  export: 'Export',
  rssi: 'rssi',
  ber: 'ber',
  reading: 'Reading',
  model: 'Model',
  email_address: 'Email Address',
  password: 'Password',
  confirm_password: 'Confirm Password',
  sign_in: 'Sign In',
  graph: 'Graph',
  no_data: 'No data',
  every: 'Every',
  for_company: 'For company',
  flow: 'Flow',
  no_data_found: 'No data found.',
  percent: 'Percent',
  device_number_devicenum: 'Device number: {{device_number}}',
  device_number: 'Device number',
  device_name: 'Device name',
  successfully_saved: 'Successfully saved',
  error_occured: 'An error occurred',
  none: 'None',
  off: 'Off',
  no_unit: 'No unit',
  hardware_version: 'Hardware Version',
  software_version: 'Software Version',
  transmission_period: 'Transmission Period',
  record_period: 'Record Period',

  createdOn: 'Created On',
  createdBy: 'Created By',

  show_average: 'Show Average',
  show_readings: 'Show Readings',

  yes: 'Yes',
  no: 'No',
  capital_yes: 'YES',
  capital_no: 'NO',

  sensor: 'Sensor',

  all_sensors: 'All Sensors',

  select_all: 'Select all',
  deselect_all: 'Deselect all',

  function_last: 'Last',
  function_first: 'First',
  function_avg: 'Average',
  function_min: 'Min',
  function_max: 'Max',
  function_median: 'Median',
  function_count: 'Count',
  function_last_flow: 'Last Flow',
  function_first_flow: 'First Flow',
  function_avg_flow: 'Average Flow',
  function_max_flow: 'Max Flow',
  function_min_flow: 'Min Flow',
  function_median_flow: 'Median Flow',

  page_title_header_number_with_name_absent: '{{title}} - {{device_number}}',
  page_title_header_number_with_name_present: '{{title}} - {{device_name}} ({{device_number}})',

  error_forbidden_operation_not_allowed: 'Not Allowed',

  not_set: 'Not set',

  //Sensor Types
  generic_analog_sensor: 'Generic Analog Sensor',
  generic_virtual_sensor: 'Generic Virtual Sensor',
  generic_digital_sensor: 'Generic Digital Sensor',
  modbus_generic_analog_sensor: 'Modbus Generic Analog Sensor',
  modbus_generic_digital_sensor: 'Modbus Generic Digital Sensor',
  pressure_sensor: 'Pressure Sensor',
  temperature_sensor: 'Temperature Sensor',
  humidity_sensor: 'Humidity Sensor',
  level_sensor: 'Level Sensor',
  weight_meter: 'Weight Meter',
  weight_sensor: 'Weight Sensor',
  load_cells: 'Load Cells',
  wind_direction_sensor: 'Wind Direction Sensor',
  barometric_pressure_sensor: 'Barometric Pressure Sensor',
  soil_moisture_sensor: 'Soil Moisture Sensor',
  dew_point_sensor: 'Dew Point Sensor',
  soil_temperature_sensor: 'Soil Temperature Sensor',
  soil_potential_sensor: 'Soil Potential Sensor',
  particulate_matter_sensor: 'Particulate Matter Sensor',
  leaf_wetness_sensor: 'Leaf Wetness Sensor',
  water_meter: 'Water Meter',
  power_meter: 'Power Meter',
  gas_flow_meter: 'Gas Flow Meter',
  rain_sensor: 'Rain Sensor',
  wind_speed_sensor: 'Wind Speed Sensor',
  heat_meter: 'Heat Meter',
  geiger_mueller_counter: 'Geiger Muller Counter',
  on_off_sensor: 'On/Off Sensor',
  on_off_input_sensor: 'On/Off Input Sensor',
  on_off_output_sensor: 'On/Off Output Sensor',
  on_off_auto_output_sensor: 'On/Off/Auto Output Sensor',
  tasmota_relay_sensor: 'Tasmota Relay Sensor',
  modbus_generic_on_off_input_sensor: 'Modbus Generic On/Off Input Sensor',
  modbus_generic_on_off_output_sensor: 'Modbus Generic On/Off Output Sensor',
  co_sensor: 'CO Sensor',
  no2_sensor: 'NO2 Sensor',
  so2_sensor: 'SO2 Sensor',
  tasmota_generic_digital_sensor: 'Tasmota Generic Digital Sensor',
  tasmota_alarm_input_sensor: 'Tasmota Alarm Input Sensor',
  tasmota_generic_analog_sensor: 'Tasmota Generic Analog Sensor',

  //Port Types
  digital_input_port: 'Digital Input Port',
  pulse_input_port: 'Pulse Input Port',
  pulse_s0_input_port: 'Pulse S0 Input Port',
  analog_input_port: 'Analog Input Port',
  '4_20_mA_input_port': '4-20mA Input Port',
  '0_5_V_input_port': '0-5V Input Port',
  on_off_input_port: 'On/Off Input Port',
  on_off_output_port: 'On/Off Output Port',
  generic_modbus_on_off_input_port: 'Generic Modbus On/Off Input Port',
  generic_modbus_on_off_output_port: 'Generic Modbus On/Off Output Port',

  /*
   * |===============|
   * | Period Picker |
   * |===============|
   */
  period_date_range: 'Date Range',
  period_custom: 'Custom',
  period_today: 'Today',
  period_week: 'Week',
  period_month: 'Month',
  period_yesterday: 'Yesterday',
  period_last_week: 'Last week',
  period_last_month: 'Last month',
  period_last_1_hours: 'Last hour',
  period_last_2_hours: 'Last 2 hours',
  period_last_4_hours: 'Last 4 hours',
  period_last_24_hours: 'Last 24 hours',
  period_last_48_hours: 'Last 48 hours',

  /*
   * |=====================|
   * | Hook Error Messages |
   * |=====================|
   */
  error_cannot_fetch_device: 'Could not fetch device.',
  error_cannot_fetch_device_config: 'Could not fetch device config.',
  error_cannot_fetch_ports_config: 'Could not fetch ports config.',
  error_cannot_fetch_flow_stats: 'Could not fetch flow statistics.',
  error_cannot_fetch_time_stats: 'Could not fetch time statistics.',
  error_cannot_fetch_general_stats: 'Could not fetch general statistics.',
  error_cannot_fetch_qflow_stats: 'Could not fetch qflow statistics.',

  signup_company_name: 'Company Name',

  sign_up_form_first_name: 'First Name',
  sign_up_form_last_name: 'Last Name',
  sign_up_form_email: 'Email Address',
  sign_up_form_password: 'Password',
  sign_up_form_confirm_password: 'Confirm Password',
  sign_up_form_company_name: 'Company Name',

  button_save: 'Save',
  button_apply: 'Apply',
  button_update: 'Update',
  button_create: 'Create',
  button_delete: 'Delete',
  button_continue: 'Continue',
  button_back: 'Back',

  capital_add_btn: 'ADD',
  capital_create_btn: 'CREATE',
  capital_update_btn: 'UPDATE',
  capital_remove_btn: 'REMOVE',
  capital_cancel_btn: 'CANCEL',

  role_roles: 'Roles',
  role_user: 'User',
  role_public: 'Public',
  role_admin: 'Admin',
  role_super_admin: 'Super Admin',
  role_restricted: 'Restricted',

  device_counters_cumulative_graph_graph: 'Graph, device number: {{device_number}}',
  device_counters_cumulative_graph_must_have_analog_sensor: 'Must have an enabled analog sensor.',

  confirm_registration_welcome_to_thingslog: 'Welcome to Thingslog!',
  confirm_registration_completed: 'REGISTRATION COMPLETED',
  confirm_registration_failed: 'REGISTRATION FAILED',
  confirm_registration_completed_description:
    'Your registration process is completed. You can login now. If you face issue, contact our support at {{email}}',
  confirm_registration_failed_description:
    'We encountered a problem with registration. If you face issues logging in, please, contact our support at {{email}}',
  confirm_registration_visit_website: 'Visit Our Website',
  confirm_registration_visit_ecommerce: 'Visit Our Online Store',

  sensor_statistics_min: 'Min: {{min}}',
  sensor_statistics_max: 'Max: {{max}}',
  sensor_statistics_avg: 'Avg: {{avrg}}',
  sensor_statistics_total: 'Total: {{total}}',

  chart_statistics_min: 'Min:',
  chart_statistics_max: 'Max:',
  chart_statistics_avg: 'Avg:',
  chart_statistics_total: 'Total:',

  menu_main: 'Main',
  menu_device: 'Device',

  submenu_devices: 'Devices',
  submenu_account: 'Account',
  submenu_device_reports: 'Device Reports',
  submenu_settings: 'Settings',

  link_selected_device: 'Selected Device',
  link_devices: 'Devices List',
  link_accounts: 'Accounts',
  link_utility_rate: 'Utility Rates',
  link_link_device: 'Link Device',
  link_create_user: 'Create User',
  link_icons: 'Icons',
  link_licenses: 'Licenses',
  link_accounts_manager: 'Create Account',
  link_transmissions: 'Transmissions',
  link_map: 'Map',
  link_inventory: 'Inventory',
  link_devices_manager: 'Device Manager',
  link_battery: 'Battery',
  device_groups: 'Device Groups',
  link_signal: 'Signal',
  link_alarms: 'Alarms',
  link_value_ranges: 'Value Range Events',
  link_device_account_lookup: 'Device Account Lookup',
  link_show_users: 'Users',
  link_dashboard: 'Dashboard',
  link_reports: 'Reports',
  link_billing_report: 'Billing Report',

  link_all_devices: 'All Devices',
  link_readings: 'Readings',
  link_flow: 'Flow',
  link_graph: 'Graph',
  link_new_graph: 'Instant Graph',
  link_pump_control: 'Pump Control',
  link_relay_control: 'Relay Control',
  link_alarm_config: 'Alarm Config',
  link_statistics: 'Statistics',
  link_customer: 'Customer',
  link_meter: 'Meter',
  link_config: 'Config Synchronization',
  link_initial_config: 'Initial Config (old)',
  link_sensor_config: 'Sensor Config (old)',
  link_sensors_and_network_config: 'Parameters Configuration',
  link_export: 'Export',
  link_customer_billing_report: 'Customer Billing Report',
  link_flow_generator: 'Flow Generator',
  link_commands: 'Commands',
  link_copy_config: 'Copy Config',
  switch_to_new_menu_label: 'Use new menu',
  switch_to_old_menu_label: 'Use old menu',

  link_profile: 'Profile',
  link_language: 'Language',
  link_sign_out: 'Sign Out',

  chip_early_access: 'Early Access',

  pulse_sensor_1: 'Pulse Sensor 1',
  pulse_sensor_2: 'Pulse Sensor 2',
  analog_sensor_1: 'Analog Sensor 1',
  analog_sensor_2: 'Analog Sensor 2',
  analog_sensor_3: 'Analog Sensor 3',
  analog_sensor_4: 'Analog Sensor 4',
  analog_sensor_5: 'Analog Sensor 5',
  analog_sensor_6: 'Analog Sensor 6',
  analog_sensor_7: 'Analog Sensor 7',
  analog_sensor_8: 'Analog Sensor 8',
  on_off_sensor_1: 'On/Off Sensor 1',
  on_off_sensor_2: 'On/Off Sensor 2',
  on_off_sensor_3: 'On/Off Relay 1',
  on_off_sensor_4: 'On/Off Relay 2',

  device_icon: 'Device Icon',
  sensor_icon: 'Sensor Icon',
  icon_no_icon: 'No Icon',
  icon_barometer: 'Barometer',
  icon_door_closed: 'Door Closed',
  icon_door_open: 'Door Open',
  icon_electricity: 'Electricity',
  icon_energy_meter: 'Energy Meter',
  icon_gas_flow_meter: 'Gas Flow Meter',
  icon_gas: 'Gas',
  icon_generic_particle_meter: 'Generic Particle Meter',
  icon_generic: 'Generic',
  icon_heat_meter: 'Heat Meter',
  icon_humidity: 'Humidity',
  icon_leaf_moisture: 'Leaf Moisture',
  icon_level_metering: 'Level Metering',
  icon_pivot: 'Pivot',
  icon_pressure: 'Pressure',
  icon_radiation: 'Radiation',
  icon_rain: 'Rain',
  icon_soil_moisture: 'Soil Moisture',
  icon_temperature: 'Temperature',
  icon_water_meter: 'Water Meter',
  icon_water: 'Water',
  icon_wind_direction: 'Wind Direction',
  icon_wind_speed: 'Wind Speed',
  icon_pump: 'Pump',
  icon_apartment: 'Apartment Building',
  icon_base_station: 'Base Station',
  icon_clinic: 'Clinic',
  icon_hospital: 'Hospital',
  icon_house: 'House',
  icon_mayor_office: "Mayor's Office Building",
  icon_police_station: 'Police Station',
  icon_public_restroom: 'Public Restroom Facility',
  icon_stadium: 'Stadium ',
  icon_gas_station: 'Gas Station',
  icon_controller: 'Controller',

  device_icon_new_portal: 'Icon (new portal)',
  device_icon_old_portal: 'Icon (old portal)',

  very_low_level_alarm: 'Very low level alarm',
  low_level_alarm: 'Low level alarm',
  high_level_alarm: 'High level alarm',
  very_high_level_alarm: 'Very high level alarm',

  sensor_conversion_formula: 'Sensor Conversion Formula',
  delete_old_counters: 'Delete old counters',
  device_config_select_record_period: 'Select Record Period',
  device_config_select_transmission_period: 'Select Transmission Period',

  devices_table_number: 'Number',
  devices_table_name: 'Name',
  devices_table_device_number: 'Device Number',
  devices_table_device_name: 'Device Name',
  devices_table_business_partner: 'Business Partner',
  devices_table_quarter: 'Quarter',
  devices_table_address: 'Address',
  devices_table_error: 'Could not load devices',
  devices_table_no_devices_found: 'No devices found',
  devices_table_description: 'Description',
  devices_column_header_search: 'Search…',

  alarm_config_header: 'Configure alarms',
  alarm_config_notification_email: 'Notification emails',
  alarm_config_notification_email_place_holder: 'Email list (comma separated)',
  alarm_config_missed_transmission_email: 'Missed transmission emails',
  alarm_config_missed_transmission_email_place_holder:
    'Missed transmissions email list (comma separated)',
  alarm_config_high_consumption_threshold:
    'High Consumption Threshold for transmission period {{transmission_every}} {{transmission_period}} [{{units}}]',
  alarm_config_low_flow_alarm_threshold: 'Low Flow Alarm Threshold [{{units}}]',
  alarm_config_high_flow_alarm_threshold: 'High Flow Alarm Threshold [{{units}}]',
  alarm_config_non_zero_consumption_time_range: 'Non-Zero consumption time range',
  alarm_config_high_low_flow_time_range: 'High/Low Flow alarm time range',
  alarm_config_start_time: 'Start time',
  alarm_config_end_time: 'End time',
  alarm_config_flow_calc_time_base: 'Flow calculation time base',
  alarm_config_saved_msg: 'Alarm Configuration Saved.',
  alarm_config_email_validation_error:
    'Please enter valid email addresses, separated by a single comma and no space, with a maximum of 255 characters.',

  alarm_config_non_zero_consumption_time_range_error: 'Must have start time and end time',
  alarm_config_low_flow_severity: 'Low Flow Severity',
  alarm_config_high_flow_severity: 'High Flow Severity',
  alarm_config_high_consumption_severity: 'High Consumption Severity',
  alarm_config_all_zero_severity: 'All Zero Consumption Severity',
  alarm_config_non_zero_consumption_severity: 'Non-Zero Consumption Severity',
  alarm_config_on_off_severity: 'On/Off Severity',

  alarms_title: 'Alarms',
  alarms_title_with_dev_num: 'Alarms, device number: {{device_number}}',
  alarms_type: 'Alarm Type',
  alarms_active_status: 'Activity',
  alarms_active_dropdown_label: 'Active',
  alarms_inactive_dropdown_label: 'Inactive',

  device_battery_title: 'Battery level',
  device_battery_voltage: 'Voltage, mV',
  device_battery_level: 'Level, %',

  device_signal_title: 'Signal strength',

  device_counters_graph_header: 'Consumption Graph',

  device_instant_graph_header: 'Instant Graph',

  devices_signal_title: 'Devices signal',
  devices_signal_level: 'Signal Level',
  devices_signal_level_percent: 'Level, %',

  devices_battery_title: 'Batteries Level',
  devices_battery_level: 'Battery Level, %',
  devices_battery_voltage: 'Voltage, mV',

  reports_export_title: 'Export Reports to file',

  device_counter_export_title: 'Export Device counters, device number: {{device_number}}',

  device_config_record_and_transmission_period: 'Record and transmission periods',
  device_config_record_period: 'Record Period',
  device_config_transmission_period: 'Transmission Period',
  device_config_start_setup: 'Start Setup',
  device_config_setup_message: 'Configuration Saved. Now put a magnet on your device.',
  device_config_header: 'Configure device',
  device_config_config_date: 'Config date: {{date}}',
  device_config_config_status: 'Status',
  device_config_config_status_configured: 'Synced',
  device_config_config_status_not_configured: 'Not Synced',
  device_config_time_zone: 'Time zone',
  device_config_pulse_per: '{{x}} per pulse',
  device_config_sensor_type: 'Sensor Type',
  device_config_meter_type: 'Meter Type',
  device_config_select_time_zone: 'Select time zone',
  device_config_missed_transmission_alarm_severity: 'Missed transmission alarm severity',
  device_config_pulse_coef_not_changed_message:
    'Warning: Custom "Pulse coefficient" remains unaffected by changes in "Initial Reading"',
  device_config_does_not_support_this_model: 'This device model is not supported on this page.',

  device_digits_fraction: 'Fraction',
  device_digits_digits: 'Digits',
  device_digits_initial_reading: 'Initial Reading',
  device_digits_alert_too_many_total_digits: 'The total number of digits should be <= 9',
  device_digits_alert_too_small_total_digits: 'The total number of digits should be >= 4',
  device_digits_alert_too_many_total_fraction:
    'The number of digits after decimal point should be <= 3 and the total number of digits should be <= 9',
  device_digits_alert_too_small_total_fraction:
    'The number of digits after decimal point should be >=0 and the total number of digits should be >= 4',

  device_transmissions_at: 'Device transmissions at {{query_date}}',
  device_transmissions_last_transmission_short: 'Last Trans.',
  device_transmissions_next_transmissions_short: 'Next Trans.',
  device_transmissions_days: 'Days',
  device_transmissions_transmissions_short: 'Trans',
  device_transmissions_count: 'Count',
  device_transmissions_fail: 'Fail',
  device_transmissions_customer: 'Customer',
  device_transmissions_address: 'Address',
  device_transmissions_ok: 'Ok',

  link_device_link_device_to_my_account: 'Link device to my account',
  link_device_device_number: 'Device Number',
  link_device_unlink_device: 'Unlink Device',
  link_device_with_device_number: `Link device: {{device_number}}`,
  unlink_device_with_device_number: `Unlink device: {{device_number}}`,
  link_device_devices_counts:
    'Linked devices: {{linked_devices_count}} / {{allowed_devices_count}}',
  link_device_success_message: 'Device associated successfully',
  unlink_device_success_message: 'Device unlinked successfully from: ',
  link_device_enter_device_number_error: 'Enter a device number',
  link_device_select_company_error: 'Select a company',
  link_device_default_error: 'There was a problem linking/unlinking the device',
  link_device_company_not_found_error: 'Company not found',
  link_device_device_not_found_error: 'Device {{device_number}} was not found',
  link_device_device_not_linked_error: 'Device {{device_number}} is not linked',
  link_device_device_already_linked_error: 'Device {{device_number}} is already linked',
  link_device_device_limit_reached_error: 'Device limit reached',
  link_device_operation_not_allowed_error: 'Operation not allowed',
  link_device_unlink_devices_for_sub_accounts: 'Unlink device for all sub accounts',
  link_device_unlink_devices_for_sub_accounts_tooltip:
    'If checked, device will be unlinked from selected and all sub accounts. If not checked, device will be unlinked only for selected account.',

  device_search_device_number: 'Device Number',

  device_manager_create_or_delete_device: 'Create or delete device: {{device_number}}',
  device_manager_device_number: 'Device Number',
  device_manager_device_model: 'Device Model',
  device_manager_sw_version_validation:
    'The required format is X.X.X or  X.X.X- and X.X.X_ followed by characters, where X is a number',
  device_manager_hw_version_validation: 'The required format is X.X.X where X is a number',
  device_manager_software_version: 'Software Version',
  device_manager_hardware_version: 'Hardware Version',

  dashboard_alarms: 'Alarms',
  dashboard_batteries: 'Batteries',
  dashboard_signal: 'Signal',
  dashboard_transmission: '{{alarm_type}} ({{alarm_count}})',
  dashboard_chart_battery_level: 'Battery level',
  dashboard_chart_signal_level: 'Signal Level',
  dashboard_chart_number_of_devices: 'Number of devices',
  dashboard_select_company_header: 'Select an account to view the dashboard',
  dashboard_select_company_from: 'From here: ',
  dashboard_select_company_accounts_page_link: 'Accounts',

  alarm_type_no_zero_consumption: 'No Zero Consumption',
  alarm_type_high_consumption: 'High Consumption',
  alarm_type_high_flow: 'High Flow',
  alarm_type_missed_transmission: 'Missed Transmission',
  alarm_type_low_flow: 'Low Flow',
  alarm_type_all_zero_consumption: 'All Zero Consumption',
  alarm_type_device_removed: 'Device Removed',
  alarm_type_on_off_alarm: 'On/Off Alarm',
  alarm_type_low_level: 'Low Level Alarm',
  alarm_type_high_level: 'High Level Alarm',
  alarm_type_tamper: 'Tamper',
  alarm_type_oversized: 'Oversized',
  alarm_type_undersized: 'Undersized',
  alarm_type_low_battery: 'Low Battery',
  alarm_type_blockage: 'Blockage',
  alarm_type_burst: 'Burst',
  alarm_type_reserve_flow: 'Reverse Flow',

  device_counter_graph_min: 'Min: {{min}}',
  device_counter_graph_max: 'Max: {{max}}',
  device_counter_graph_avg: 'Avg: {{avrg}}',
  device_counter_graph_total: 'Total: {{total}}',
  device_counter_graph_average: 'Average:',
  device_counter_graph_no_data: 'No data',
  device_counter_graph_cant_calculate_flow_analog: "Can't calculate flow for an analog sensor!",
  device_counter_graph_must_have_digital_sensor: 'Must have an enabled digital sensor.',

  customer_info_header: 'Customer Info',
  customer_info_business_partner_number: 'Business Partner Number',
  customer_info_contact_data: 'Contact Data',
  customer_info_contract_number: 'Client Contract Number',
  customer_info_name: 'Name',
  customer_info_description: 'Description',
  customer_info_billing_group: 'Billing Group',
  customer_info_municipality: 'Municipality',
  customer_info_quarter: 'Quarter',
  customer_info_street: 'Street',
  customer_info_block: 'Block',
  customer_info_entr: 'Entr.',
  customer_info_customer_type: 'Customer Type',
  customer_info_customer_type_commercial: 'Commercial',
  customer_info_customer_type_domestic: 'Domestic',
  customer_info_customer_type_block_of_flats: 'Block of Flats',
  customer_info_customer_type_budget: 'Budget',

  meter_info_meter_info: 'Meter Info: {{device_number}}',
  meter_info_factory_number: 'Factory Number',
  meter_info_brand: 'Brand',
  meter_info_enter_diameter: 'Enter Diameter',
  meter_info_mount_date: 'Mount Date',
  meter_info_meter_info_saved: 'Meter Info Saved.',

  device_initial_config_header: 'Device Initial Config',
  device_initial_device_model: 'Device Model',
  device_initial_config_address: 'Address',
  device_initial_config_port: 'Port',
  device_initial_config_resolve_address: 'Resolve Address (use DNS)',
  device_initial_config_simcard_provider: 'SimCard Provider',
  device_initial_config_simcard_number: 'SimCard Number',
  device_initial_config_bands: 'bands',
  device_initial_config_OTAA_appkey: 'OTAA - appkey',
  device_initial_config_OTAA_deveui: 'OTAA - deveui',
  device_initial_config_OTAA_appeui: 'OTAA - appeui',
  device_initial_config_ABP_appskey: 'ABP - appskey',
  device_initial_config_ABP_nwkskey: 'ABP - nwkskey',
  device_initial_config_ABP_devaddr: 'ABP - devaddr',
  device_initial_config_ABP_deveui: 'ABP - deveui',
  device_initial_config_lora_app_mode: 'App mode',
  device_initial_config_app_id: 'App Id',
  device_initial_config_profile_id: 'Profile Id',
  device_initial_config_platform_name: 'Platform Name',
  device_initial_config_thingslog_lora_platform: 'ThingsLog LoRa Platform',
  device_receive_data_binary: 'Receive Data Binary',
  device_send_data_binary: 'Send Data Binary',
  device_mqtt_config: 'MQTT Config',
  device_mqtt_config_qos: 'Quality of Service',
  device_mqtt_config_username: 'Username',
  device_mqtt_config_password: 'Password',
  device_mqtt_config_certificate: 'Certificate',
  device_initial_config_apn_username: 'APN Username',
  device_initial_config_apn_password: 'APN Password',
  device_initial_config_net_attached_delay: 'Transmission Delay',
  device_initial_config_gprs_radio_mode: 'GPRS Radio Mode',
  device_initial_config_grps_radio_mode_auto_option: 'Auto',
  device_initial_config_preferred_network_type: 'Preferred Network Type',
  device_initial_config_preferred_network_default_option: 'Default',
  device_initial_config_mcc_mnc: 'MCC-MNC',
  device_initial_config_slave_devices: 'Slave Devices',
  device_initial_config_modbus_address: 'Modbus Address',
  device_initial_config_modbus_master_device: 'Master Device Number',

  sensor_config_header: 'Sensor Config',
  sensor_config_name: 'Name',
  sensor_config_sensor_name: 'Sensor Name',
  sensor_config_sensor_icon: 'Sensor Icon',
  sensor_config_description: 'Description',
  sensor_config_model: 'Model',
  sensor_config_serial_number: 'Serial Number',
  sensor_config_manufacturer: 'Manufacturer',
  sensor_config_diameter: 'Diameter',
  sensor_config_price: 'Price',
  sensor_config_currency: 'Currency',
  sensor_config_supported_visualization_types: 'Supported Visualization Types',
  sensor_config_port_type: 'Port Type',
  sensor_config_port_type_digital_input_port: 'Digital Input Port',
  sensor_config_port_type_analog_input_port: 'Analog Input Port',
  sensor_config_port_type_pulse_s0_input_port: 'Pulse S0 Input Port',
  sensor_config_port_type_pulse_input_port: 'Pulse Input Port',
  sensor_config_port_type_on_off_input_port: 'On Off Input Port',
  sensor_config_port_type_on_off_output_port: 'On Off Output Port',
  sensor_config_port_conversion_formula: 'Port Conversion Formula',
  sensor_config_formula: 'Formula',
  sensor_config_offset: 'Offset',
  sensor_config_sensor_index_tooltip: 'Sensor index: {{sensor_index}}',
  sensor_config_visible: 'Visibility',
  sensor_config_order_number: 'Order Number',
  sensor_config_visible_true: 'Visible',
  sensor_config_visible_false: 'Invisible',
  sensor_config_use_in_sensor_aggregation: 'Use In Sensor Aggregation',
  sensor_config_use_in_sensor_aggregation_true: 'Use',
  sensor_config_use_in_sensor_aggregation_false: 'Do Not Use',
  sensor_config_value_labels: 'Value Labels',
  sensor_config_label_for_value_zero: 'Label for value "0"',
  sensor_config_label_for_value_one: 'Label for value "1"',
  sensor_config_inverted_read_state_value: 'Inverted Read State',
  sensor_config_inverted_read_state_value_true: 'Inverted',
  sensor_config_inverted_read_state_value_false: 'Non-inverted',
  sensor_config_register_address: 'Register Address',
  sensor_config_data_type: 'Data Type',
  sensor_config_count: 'Count',
  sensor_config_number_bytes: 'Number Bytes',
  sensor_config_particle_size: 'Particle size',
  sensor_config_power: 'Power',
  sensor_config_measurement_type: 'Measurement Type',
  sensor_config_measurement_group: 'Measurement Group',

  device_counter_statistics_statistics_id: 'Statistics, id: {{device_number}}, {{description}}',
  device_counter_statistics_quarter: 'Quarter',
  device_counter_statistics_flow: 'Flow',
  device_counter_statistics_hour: 'Hour',
  device_counter_statistics_quantity: 'Quantity',
  device_counter_statistics_value: 'Value',
  device_counter_statistics_digital_ports_must_be_present:
    'You must have a pulse input connected to a water meter sensor to see this table.',
  device_counter_statistics_must_have_initialized_params:
    'Parameters Q3, R, and Diameter are required on pulse input connected to a water meter sensor.',

  company_manager_title: 'Create or delete account: {{company_name}}',
  company_manager_account_name: 'Account Name',
  company_manager_solution: 'Solution Type',
  company_manager_solution_option_none: 'None',
  company_manager_solution_option_agri: 'Agriculture',
  company_manager_solution_option_air_quality: 'Air Quality',
  company_manager_solution_option_street_lights: 'Street Lights',
  company_manager_solution_option_pump_station: 'Pumping Station',
  company_manager_license: 'License',
  company_manager_account: 'Account',
  company_manager_create_company: 'Create Account',
  company_manager_create_company_error: 'There was a problem with creating your account',
  company_manager_license_info_box:
    'The license attachment requires a valid Account ID. Double-check your Account ID value if you want to include it in the creation process.',
  company_manager_valid_from: 'Valid From',
  company_manager_valid_to: 'Valid To',
  company_manager_solution_type: 'Solution Type',
  company_manager_contact_id: 'Contact ID',
  company_manager_account_id: 'Account ID',
  company_manager_license_type: 'License Type',
  company_manager_timezone: 'Timezone',
  company_manager_country: 'Country',
  company_manager_countries_error: 'Could not load countries',
  company_manager_timezones_error: 'Could not load timezones',
  company_manager_account_info: 'Account Info',
  company_manager_address: 'Address',
  company_manager_city: 'City',
  company_manager_municipality: 'Municipality',
  company_manager_area: 'Area',
  company_manager_zip_code: 'ZIP Code',
  company_manager_vat_number: 'VAT Number',

  company_manager_license_per_device: 'Per Device',
  company_manager_license_per_reading: 'Per Reading',
  company_manager_license_per_sensor: 'Per Sensor',
  company_manager_license_per_poc: 'Per Proof of Concept (POC)',
  company_manager_license_per_remote_node: 'Per Remote Node',

  company_manager_creating_account: 'Creating account: {{company_name}}',
  company_manager_deleting_account: 'Deleting account: {{company_name}}',
  company_manager_account_created: 'Account created successful',
  company_manager_account_deleted: 'Account deleted successful',

  company_manager_update_company: 'Update Account',
  company_manager_delete_company: 'Delete Account',
  company_manager_actions: 'Actions',
  company_manager_delete_modal_description:
    'Are you sure you want to delete this account, the action is irreversible?',
  company_manager_confirm_button: 'Confirm',
  company_manager_cancel_button: 'Cancel',
  company_manager_delete_company_error_message: 'An error occured during deleting the account',
  company_manager_update_btn: 'Update',
  company_manager_child_company_warning_message: 'Creating a sub-account of',
  company_manager_current_logged_in_company: 'the account you are currently logged into',
  company_manager_current_selected_company: 'the account you have currently selected',
  accounts_child_companies: 'Child Companies',
  accounts_contactRef: 'Contact ID',
  accounts_accountRef: 'Account ID',
  company_manager_accountRef_is_disabled_because_of_license_type:
    'Account ID is disabled because there is per POC license',
  accounts_none: 'None',
  company_manager_update_company_success: 'Account updated successfully',
  company_manager_update_company_error: 'An error occurred while updating account',
  company_manager_account_name_exists: 'An account with this name already exists.',

  license_max_readings: 'Max Readings',
  license_max_devices: 'Max Devices',
  license_max_sensors: 'Max Sensors',

  license_manager_title: 'Update license: {{company_name}}',
  license_manager_license: 'License',
  license_manager_license_updated: 'License updated',

  companies_title: 'Number of accounts: {{company_number}}',
  companies_table_name: 'Name',
  companies_number_of_accounts: 'Number of accounts:',
  companies_search: 'Search',

  users_title: 'Users',
  units_type: 'Units Type',

  reports_export_format_type: 'Format',

  login_form_forgot_password: 'Forgot password?',

  pump_auto_every: 'Auto every {{secs}} secs',
  pump_svg_last_updated: 'Last updated: {{timestamp}}',
  pump_svg_power_supply: 'Power Supply',
  pump_svg_water_level_controller: 'Water Level Controller',
  pump_svg_pump: 'Pump',
  pump_svg_level_sensor: 'Level Sensor',
  pump_svg_pump_on_sensor: 'Pump-on Sensor',
  pump_svg_pump_off_sensor: 'Pump-off Sensor',
  pump_svg_dry_run_sensor: 'Dry Run Sensor',

  /*
   * |===============|
   * | Device Groups |
   * |===============|
   */

  // Errors
  device_groups_error_please_select_company: 'Please select a company.',

  // Buttons
  device_groups_btn_add: 'Add',
  device_groups_btn_edit: 'Edit',
  device_groups_btn_cancel: 'Cancel',
  device_groups_btn_delete: 'Delete',
  device_groups_color_picker_label: 'Device Group Color',

  // Tooltips
  device_groups_tooltip_edit: 'Edit',
  device_groups_tooltip_delete: 'Delete',

  // Page
  device_groups_name: 'Name',
  device_groups_devices: 'Devices',
  device_groups_view_devices: 'View {{count}} devices',
  device_groups_group_name: 'Group Name',
  device_groups_parent_device_group: 'Parent Device Group',
  device_groups_menuitem_none: 'None',
  device_groups_add_device_group: 'Add Device Group',
  device_groups_edit_device_group: 'Edit Device Group',
  device_groups_delete_device_group: 'Delete Device Group',

  device_grous_add_sensor_device: 'Add sensor and device to group',

  device_groups_table_sensor_group_name_header: 'Sensor Group Name',
  device_groups_table_parent_group_name_header: 'Parent Group Name',
  device_groups_table_device_count_header: 'Device Count',

  device_groups_sensor_table_device_number_header: 'Device Number',
  device_groups_sensor_table_sensor_name_header: 'Sensor Name',
  device_groups_sensor_table_sensor_index_header: 'Sensor Index',
  device_groups_sensor_table_select_message: 'Select a sensor group',

  device_groups_sensors_add_modal_device_placeholder: 'Device Number',
  device_groups_sensors_add_modal_sensor_placeholder: 'Sensor',
  device_groups_sensors_add_modal_device_group_name_required: 'Device group name is required',

  device_groups_sensors_delete_description:
    'Deleting this configuration will permanently remove it from the server.',
  device_groups_device_group_delete_description:
    'Deleting the device group will permanently remove it from the server.',

  device_groups_select_company_header: 'Select a company to proceed',
  device_groups_select_company_from: 'From here: ',
  device_groups_select_company_accounts_page_link: 'Accounts Page',

  device_groups_create_device_group_error: 'There was a problem creating a new device group',
  device_groups_update_device_group_error: 'There was a problem updating the device group',
  device_groups_delete_device_group_error: 'There was a problem deleting the device group',
  device_groups_add_device_sensor_device_group_error: 'There was a problem adding the sensor',
  device_groups_remove_device_sensor_device_group_error: 'There was a problem removing the sensor',

  /*
   * |==============|
   * | Users Config |
   * |==============|
   */

  //Page
  users_header: 'Users',
  users_table_username: 'Username',
  users_table_first_name: 'First Name',
  users_table_last_name: 'Last Name',
  users_table_company_name: 'Account',
  users_table_email: 'Email',

  //Filters
  users_filter_username: 'Filter Username',
  users_filter_first_name: 'Filter First Name',
  users_filter_last_name: 'Filter Last Name',
  users_filter_email: 'Filter Email',
  users_filter_company_name: 'Filter Account',
  users_reset_filter_icon: 'Reset Filters',
  users_table_is_contact_person: 'Contact Person',

  //Modals
  users_add_modal_header: 'Add User',
  users_update_modal_header: 'Update User',
  user_remove_modal_icon: 'Delete User',
  users_remove_modal_header: 'Are you sure you want to delete user {{username}}?',
  users_remove_modal_body:
    'Clicking remove will permanently delete the chosen user from the server',
  users_is_dashboard_enabled: 'Enable Dashboard',
  users_is_contact_person: 'Contact Person',

  //Errors
  users_password_match: 'Passwords do not match',
  users_email_blank: 'Email cannot be blank',
  users_load_error: 'Could not load users',
  users_update_error: 'Could not update user',
  users_delete_error: 'Could not delete user',
  users_create_error: 'Could not create user',

  // Success
  users_update_success: 'User updated successfully',
  users_delete_success: 'User deleted successfully',
  users_create_success: 'User created successfully',
  /*
   * |=====================|
   * | Value Ranges Config |
   * |=====================|
   */

  // Buttons & tooltips
  value_ranges_btn_add: 'Add',
  value_ranges_btn_defaults: 'Defaults',
  value_ranges_line_color: 'Line Color',
  value_ranges_node_color: 'Node Color',
  value_ranges_foreground_color: 'Foreground Color',
  value_ranges_background_color: 'Background Color',
  value_ranges_loading: 'Loading...',
  value_ranges_nothing_to_show: 'Nothing to show',

  //Page
  value_ranges_header: 'Value Range Events',
  value_ranges_trigger: 'Trigger',
  value_ranges_notifications: 'Notifications',
  value_ranges_before: 'Before',
  value_ranges_after: 'After',
  value_ranges_disabled: 'Disabled',
  value_ranges_date_range: 'Date range',
  value_ranges_daily_time_slot: 'Daily time slot',
  value_ranges_timezone: 'Timezone',

  //Modals
  value_ranges_add_event: 'Add Event',
  value_ranges_edit_event: 'Edit Event',
  value_ranges_add_default_events: 'Add Default Events',
  value_ranges_remove_modal_header: 'Are you sure you want to delete value range',
  value_ranges_remove_modal_body: 'Clicking remove will permanently delete the chosen value range',

  //Form
  value_range_form_name: 'Event Name',
  value_range_form_function: 'Function',
  value_range_form_sensor: 'Sensor',
  value_range_form_description: 'description',
  value_range_form_min: 'Min',
  value_range_form_max: 'Max',
  value_range_form_severity: 'Severity',
  value_range_form_start_day: 'Start Day',
  value_range_form_start_month: 'Start Month',
  value_range_form_start_time: 'Start Time',
  value_range_form_end_day: 'End Day',
  value_range_form_end_month: 'End Month',
  value_range_form_end_time: 'End Time',
  value_range_form_enable_events: 'Enable Events?',
  value_range_form_select_color: 'Selected color',
  value_range_form_notification_email: 'Email',
  value_range_form_notification_push_notification: 'Push Notification',
  value_range_form_notification_sms: 'SMS',
  value_range_form_notification_push: 'Push Notification',
  value_range_form_notification_call: 'Call',
  value_range_form_line_custom_color: 'Custom Line Color',
  value_range_form_marker_custom_color: 'Custom Marker Color',
  value_range_form_no_notifications: 'No Notifications',

  //Errors
  value_range_tooltip_severity:
    'Severities that are greater than 7 are considered severe. Severe events are signified with an exclamation mark.',
  value_range_error_add: 'Could not add event',
  value_range_error_edit: 'Could not edit event',
  value_range_name_is_required: 'Name is required',
  value_range_name_already_exists: 'Event name already exists for this device and sensor',
  value_range_name_incorect_time: 'Incorrect time format',
  value_range_min_greater_than_max: 'Min cannot be greater than max',
  value_range_min_equal_to_max: 'Min cannot be equal to max',
  value_range_min_max_is_required: 'Min or Max is required',
  value_range_error_occured: 'An error occurred',
  value_range_start_incorrect_time:
    'Start time has incorrect format. It should be HH:mm. Correct: 01:00. Incorrect: 1:00.',
  value_range_end_incorrect_time:
    'End time has incorrect time format. It should be HH:mm. Correct: 01:00. Incorrect: 1:00.',
  value_range_start_day_missing_start_month: 'Start day should have start month',
  value_range_end_day_missing_end_month: 'End day should have end month',
  value_range_start_month_missing_start_day: 'Start month should have start day',
  value_range_end_month_missing_end_day: 'End month should have end day',
  value_range_mixed_alphabets_error: 'Detected mixed alphabet usage, use only one language',

  /*
   * |==========|
   * | Commands |
   * |==========|
   */

  // Command State
  commands_command_state_pending: 'Pending',
  commands_command_state_sent: 'Sent',
  commands_command_state_cancelled: 'Cancelled',
  commands_command_state_status_ok: 'Ok',
  commands_command_state_status_nok: 'Not Okay',
  commands_command_state_unknown: 'Unknown',
  commands_command_state_timeout: 'Timeout',

  // Command Type
  commands_command_type_relay_switch: 'Relay Switch',
  commands_command_type_single_relay_switch: 'Single Relay Switch',
  commands_command_type_relay_switch_with_delay: 'Relay Switch With Delay',
  commands_command_type_relay_statuses: 'Relay Statuses',
  commands_command_type_send_config_over_mqtt: 'Send config over MQTT',
  commands_command_type_send_date_over_mqtt: 'Send date over MQTT',
  commands_command_type_modbus: 'Modbus',
  commands_command_type_tasmota_relay_switch: 'Tasmota Relay Command',

  // Errors
  commands_error_missing_device_number: 'Device Number missing or wrong.',

  // Buttons
  commands_btn_send_command: 'Send Command',
  commands_btn_refresh: 'Refresh',
  commands_btn_submit: 'Submit',

  // Tooltips
  commands_tooltip_edit: 'Edit',

  // Popups
  commands_send_command: 'Send Command',
  commands_edit_command: 'Edit Command',
  commands_command_type: 'Command Type',
  commands_parameters: 'Parameters',
  commands_parameter_name: 'Parameter Name',
  commands_parameter_value: 'Parameter Value',

  // Page
  commands_header: 'Commands',
  commands_type: 'Type',
  commands_status: 'Status',
  commands_relay_1_status: 'Relay 1 Status',
  commands_relay_2_status: 'Relay 2 Status',
  commands_created: 'Created',
  commands_sent: 'Sent',
  commands_executed: 'Executed',
  commands_relay_status: 'Relay Status',
  commands_relay_index: 'Relay Index',
  commands_device_number_slave: 'Slave Number',
  commands_sensor_index: ' Sensor Index',

  /*
   * |=============|
   * | Copy Config |
   * |=============|
   */

  // Page
  copy_config_copy_config: 'Copy Config',
  copy_config_to_device_number_with_name_absent: 'To device: {{device_number}}',
  copy_config_to_device_number_with_name_present: 'To device: {{device_name}} ({{device_number}})',
  copy_config_from_device_number: 'From Device Number',
  copy_config_copy_config_type_label: 'Copy config type',
  copy_config_existing_device_option: 'Existing device',
  copy_config_new_device_option: 'New device',
  copy_config_should_copy_label: 'Should copy:',
  copy_config_should_copy_config_option: 'Config',
  copy_config_should_copy_value_ranges_option: 'Value ranges',

  // Buttons
  copy_config_btn_copy: 'Copy',

  //Toast
  copy_config_toast_copied_success: 'Device Config copied successfully.',
  copy_config_toast_copied_error: 'There was a problem trying to copy Device Config',
  copy_config_value_ranges_toast_copied_success: 'Value Ranges copied successfully.',
  copy_config_value_ranges_toast_copied_error: 'There was a problem trying to copy Value Ranges',

  /*
   * |============|
   * | Statistics |
   * |============|
   */
  statistics_header: 'Statistics',
  statistics_header_hours: 'Hours',
  statistics_header_quantity: 'Quantity',
  statistics_header_value: 'Value',
  statistics_quantity_minimum: 'Minimum',
  statistics_quantity_maximum: 'Maximum',
  statistics_quantity_average: 'Average',
  statistics_quantity_total: 'Total',
  statistics_quantity_hours: 'Hours',
  statistics_quantity_counters: 'Counters',

  // Errors
  statistics_error_definition_for_q3:
    'Sensor {{name}} does not contain a definition for q3. Please visit the sensor configuration page.',
  statistics_eror_definition_for_diameter:
    'Sensor {{name}} does not contain a definition for diameter. Please visit the sensor configuration page.',
  statistics_error_definition_for_r:
    'Sensor {{name}} does not contain a definition for r. Please visit the sensor configuration page.',
  statistics_error_no_water_meters_found:
    'No water meters found. Please visit the device configuration page.',

  /*
   * |=====|
   * | Map |
   * |=====|
   */
  map_add_device: 'Add {{device}}',
  map_edit_device: 'Edit {{device}}',
  map_lat: 'Latitude',
  map_long: 'Longitude',
  map_coordinates: 'Coordinates',
  map_polygon: 'Polygon',
  map_linked_in_polygons: 'Linked in polygons:',
  map_loading: 'Loading...',

  // Button
  map_btn_link_device: 'Link Device',
  map_btn_submit: 'Submit',

  // Error
  map_err_add_device_to_map: 'Could not add device to map.',
  map_err_change_location: 'Could not change location.',
  map_err_link_polygon: 'Could not link to polygon.',

  // Device Modal
  map_btn_get_curr_location: 'Get Current Location',
  map_geolocation_not_supported: 'Geolocation is not supported by this browser.',
  map_lat_validation_message: 'Invalid latitude',
  map_long_validation_message: 'Invalid longitude',
  map_invalid_location: 'Invalid location',
  map_invalid_coordinate_input:
    'Invalid coordinate input. It should be "lat, long", e.g. 42.123, 23.123',

  // Set Coordinates Modal
  map_coordinates_modal_btn_label: 'Set Coordinates',
  map_coordinates_modal_title: 'Set Coordinates',
  map_coordinates_modal_target_option_self: 'For Self',
  map_coordinates_modal_target_option_company: 'For Company',
  map_coordinates_modal_target_option_user: 'For User',
  map_coordinates_modal_select_user_autocomplete_label: 'Username',
  map_coordinates_modal_save_for_self_warning_message:
    'Saving will set the current coordinates as the default for your current user. Are you sure you want to proceed?',
  map_coordinates_modal_save_for_company_warning_message:
    'Saving will set the current coordinates as the default for each user in your company. Are you sure you want to proceed?',
  map_coordinates_modal_save_for_user_warning_message:
    'Saving will set the current coordinates as the default for the selected user. Are you sure you want to proceed?',
  map_coordinates_modal_confirm_btn_label: 'Confirm',
  map_coordinates_modal_cancel_btn_label: 'Cancel',

  /*
   * |===============|
   * | Sensor Config |
   * |===============|
   */

  flow_time_units: 'Time units',
  flow_measurement_units: 'Units',
  flow_configuration_label: 'Flow Configuration',
  flow_coefficient: 'Coefficient',
  sensor_config_sensor_group_name: 'Sensor Group Name',
  flow_configuration_alert_message: 'Check the following flow configurations:\n{{message}}',
  flow_configuration_invalid_sensor_row_message:
    'Index: {{sensor_index}} with name: {{sensor_name}}\n',
  flow_configuration_sensor_name_not_set_label: 'Not set',

  /*
   * |===================|
   * | Device Prediction |
   * |===================|
   */
  device_prediction: 'Device Prediction',
  device_prediction_header: 'Time Series Analysis',
  device_prediction_sensor_name: 'Sensor Name',
  device_prediction_forward_days: 'Forward Days',
  device_prediction_backward_days: 'Backward Days',
  device_prediction_generate_btn: 'Generate',
  device_prediction_error: 'Error',
  device_prediction_error_msg_json: 'There was an issue generating prediction data.',
  device_prediction_error_msg_image: 'There was an issue generating the prediction image.',
  device_prediction_date: 'Date',
  device_prediction_trend: 'Trend',
  device_prediction_time: 'Time',
  device_prediction_legend_prediction: 'Prediction',
  device_prediction_legend_range: 'Prediction Range',
  device_prediction_legend_consumption: 'Current consumption',
  device_prediction_help_title: 'Help',
  device_prediction_help_title_input_guide: 'Input Guide',
  device_prediction_help_input_guide_text:
    '<strong> 1. Period </strong> - Users can select a date range from predefined options or set a custom range. The system analyzes the data within this chosen period to calculate future predictions, offering valuable insights. <br> <strong> 2. Sensor Name </strong> – select the sensor which you want to see their future predictions. <br> <strong> 3. Every </strong> - used for more detailed prediction in terms of granularity of time. <br> <strong> 4. Forward days </strong> - number of days the user wants to predict into the future. These predictions extend beyond the selected date range, giving insights into the expected consumption behavior. <br> <strong> 5. Backward days </strong> - represents the number of days to display on the graph and table, starting from the last day from the selected period. This showcases the historical real data received from the device during this specified period. <br> <strong> 6. Generate button </strong> – when clicking “Generate,” the system calculates the consumption based on the selected settings and generates predictions. The results are then displayed in both the Graph View and the Table View. <br> <strong> 7. Graph </strong> – The graph displays both the forward and backward days, presenting the real received data and showcasing the predicted data. <br> <strong> 8. Table </strong> - The table displays all data from the "from period" to the end of "forward days." Each row includes a specific date, time, and corresponding trend value, indicating the data\'s general direction or pattern over time. It helps understanding whether the values are increasing, decreasing, or stable during the predicted period.',
  device_prediction_help_title_legend: 'Legend',
  device_prediction_help_black:
    '<strong> Black Color </strong> - represents the real consumption data during the selected period (example: from 01 July to 31 July.)',
  device_prediction_help_red:
    '<strong> Red Color </strong> -  Represents real consumption if there is data from outside the selected period. In the picture above there is real data received from the device in the days that the model is set to predict. So, user can compare the predicted values (dark blue) with the red (real data)',
  device_prediction_help_dark_blue:
    " <strong> Dark Blue Color </strong> -  Represents the prediction for the selected period, which includes old data, and extends into the future with the forward days' predicted values. These predicted values provide insights into the expected consumption trend.",
  device_prediction_help_light_blue:
    '<strong> Light Blue Color </strong> -  Represents the range of the predicted values. It signifies that the actual consumption data could fall within this range, indicating the uncertainty associated with predictions.',
  device_prediction_help_example:
    "Example: The 'FROM' date is set to 1st July and the 'TO' date to 31st July, with 'Forward days' of 30, 'Backward days' to 30, and 'EVERY' 10 minutes, the graph will display the 30 days real consumption available (1st July to 1st August) and the predicted values for 30 days forward (from 1st August to 1st September). The table will show the real and predicted consumption for every 10 min from 1 July till 1 September 2023",

  /*
   * |=================|
   * | Counters Export |
   * |=================|
   */

  // Buttons and dropdowns
  counters_export_header: 'Data export',
  counters_readings_header: 'Readings',
  counters_export_search: 'Search',
  counters_export_download: 'Download',
  counters_export_format: 'Format',

  // Table columns
  counters_export_table_device_name: 'Device Name',
  counters_export_table_device_number: 'Device Number',
  counters_export_table_sensor_index: 'Sensor Index',
  counters_export_table_sensor_name: 'Sensor Name',
  counters_export_table_date: 'Date',
  counters_export_table_counters: 'Readings',
  counters_export_table_prev_counters: 'Prev. Readings',
  counters_export_table_dt: 'dt',
  counters_export_table_delta: 'Delta',
  counters_export_error_alert_message: 'An error occurred while generating the data.',
  counter_export_select_devices_label: 'Select devices',
  counter_export_select_sensors_label: 'Select sensors',
  counter_export_sensor_index: 'Sensor index: {{sensor_index}}',

  counters_export_maximum_capacity_error_message: 'Export maximum capacity reached!',
  counters_export_maximum_capacity_suggestion_message:
    'Please decrease the amount of selected devices or date period.',
  /*
   * |====================|
   * | Consumption Export |
   * |====================|
   */

  // Buttons and dropdowns
  consumption_export_header: 'Report',
  consumption_export_search: 'Search',
  consumption_export_download: 'Download',
  consumption_export_format: 'Format',
  consumption_export_aggregation_period: 'Aggregation period',
  consumption_export_aggregation_options_none: 'None',
  consumption_export_aggregation_options_daily: 'Daily',
  consumption_export_aggregation_options_weekly: 'Weekly',
  consumption_export_aggregation_options_monthly: 'Monthly',
  consumption_export_aggregation_options_yearly: 'Yearly',

  // Table columns

  consumption_export_table_device_number: 'Device Number',
  consumption_export_table_sensor_index: 'Sensor Index',
  consumption_export_table_address: 'Address',
  consumption_export_table_sensor_name: 'Sensor Name',
  consumption_export_table_first_reading_date: 'First Reading Date',
  consumption_export_table_first_reading_value: 'First Reading Value',
  consumption_export_table_last_reading_date: 'Last Reading Date',
  consumption_export_table_last_reading_value: 'Last Reading Value',
  consumption_export_table_delta: 'Delta',
  consumption_export_table_average: 'Average',
  consumption_export_table_consumption_for_period: 'Consumption for period',

  /*
   * |=================|
   * | Alarms Export |
   * |=================|
   */

  // Buttons and dropdowns
  alarms_export_header: 'Alarms',
  alarms_export_search: 'Search',
  alarms_export_refresh: 'Refresh',
  alarms_export_download: 'Download',
  alarms_export_format: 'Format',

  alarms_return_to_alarms: 'Return to Alarms',
  alarms_redirect_to_graph: 'Redirect to Graph',

  // Table columns
  alarms_export_table_date: 'Date',
  alarms_export_table_device_number: 'Device number',
  alarms_export_table_device_name: 'Device name',
  alarms_export_table_severity: 'Severity',
  alarms_export_table_sensor_index: 'Sensor index',
  alarms_export_table_sensor_name: 'Sensor name',
  alarms_export_table_alarm_type: 'Type',
  alarms_export_table_alarm_status: 'Status',
  alarms_export_table_description: 'Description',
  alarms_export_table_link_to_graph: 'Link to graph',
  alarms_export_chip_status_active: 'Active',
  alarms_export_chip_status_inactive: 'Inactive',
  alarms_export_switch_title_label: 'Аuto-refresh',
  alarms_export_error_alert_message: 'An error occurred while generating the data.',
  alarms_export_select_company_alert_message: 'Select a company before fetching alarms',
  alarms_export_select_device_or_company_alert_message:
    'Select a device or company before fetching alarms',
  alarms_export_error_too_big_query:
    'Retrieving history from Super Admin for all devices is too big of a request. Please select a company.',

  /*
   * |================|
   * | Inventory page |
   * |================|
   */

  //table
  device_inventory_page_header: 'Inventory',
  device_inventory_column_header_search: 'Search…',
  device_inventory_description: 'Description',
  device_inventory_device_name: 'Device Name',
  device_inventory_device_number: 'Device Number',
  device_inventory_hardware_version: 'Hardware Version',
  device_inventory_model: 'Model',
  device_inventory_software_version: 'Software Version',
  device_inventory_actions: 'Actions',
  nomenclature: 'Nomenclature',

  //modal
  device_inventory_add_device: 'Add Device',
  device_inventory_create_device_button: 'Create Device',
  device_inventory_delete_unlink_modal_body:
    'This will permanently delete the chosen device or you can unlink it from the selected company. The device will be unlinked from current account and all sub-accounts. If you want to unlink the device from the current account only, please go to "Link Device" page.',
  device_inventory_delete_modal_body: 'This will permanently delete the chosen device.',
  device_inventory_hardware_version_helper: 'The required format is X.X.X where X is a number',
  device_inventory_update_device_button: 'Update Device',
  device_inventory_update_device_title: 'Updating {{number}}',
  device_inventory_modal_delete_device_title: 'Delete Device {{number}} ?',
  device_inventory_software_version_helper:
    'The required format is X.X.X or  X.X.X- and X.X.X_ followed by characters, where X is a number',
  device_inventory_description_helper: 'Reached description length limit',
  device_inventory_device_number_helper:
    'Please enter 8 characters containing only letters (A-Z) and numbers (0-9).',
  device_inventory_modal_select_action: 'Select action for device {{number}}',

  //success
  device_inventory_create_device_success: 'Device created successfully',
  device_inventory_update_device_success: 'Device updated successfully',
  device_inventory_delete_device_success: 'Device deleted successfully',
  device_inventory_unlink_device_success: 'Device successfully unlinked',

  //error
  device_inventory_create_device_error: 'An error occurred while creating device',
  device_inventory_delete_device_error: 'An error occurred while deleting device',
  device_inventory_device_exists: 'Device already exists',
  device_inventory_empty_device_error: "Device number can't be empty",
  device_inventory_empty_hardware_error: "Hardware version can't be empty",
  device_inventory_empty_software_error: "Software version can't be empty",
  device_inventory_error: 'Could not load devices',
  device_inventory_update_device_error: 'An error occurred while updating device',
  device_inventory_already_exists_error: 'Device with this number already exists',
  device_inventory_empty_nomenclature_error: 'Nomenclature cannot be empty',

  /*
   * |====================|
   * | Transmissions page |
   * |====================|
   */

  //page
  transmissions_page_title: 'Transmissions',
  transmissions_page_refresh: 'Refresh',
  transmissions_page_export_csv: 'Export to CSV',
  transmissions_page_yes: 'Yes',
  transmissions_page_no: 'No',
  transmissions_page_error_message: 'Could not load transmissions',

  //table
  transmissions_table_device_number: 'Device Number',
  transmissions_table_device_name: 'Device Name',
  transmissions_table_last_transmission: 'Last Transmission',
  transmissions_table_next_transmission: 'Next Transmission',
  transmissions_table_days: 'Days',
  transmissions_table_transmission: 'Transmission',
  transmissions_table_count: 'Count',
  transmissions_table_customer: 'Customer',
  transmissions_table_address: 'Address',
  transmissions_table_ok: 'OK',
  transmissions_table_devEUI: 'devEUI',
  transmissions_table_simcard_number: 'SimCard Number',
  transmissions_table_simcard_provider: 'SimCard Provider',
  transmissions_table_apn: 'APN',

  /*
   * |===============|
   * | Device Config |
   * |===============|
   */

  // page
  device_config_period: 'Period',
  device_config_every: 'Every',

  // error
  device_config_error_record_period_postiive: 'Record period must be positive.',
  device_config_error_transmission_period_positive: 'Transmission period must be positive.',
  device_config_error_record_must_be_greater_than_transmission:
    'Record period cannot be greater than transmission period.',
  device_config_error_not_divisible: 'Record period is not divisible to transmission period.',
  device_config_error_max_threshold_reached:
    '{{curr}} records per transmission is more than the maximum {{max}}.',

  /*
   * |=======|
   * | Graph |
   * |=======|
   */
  graph_tabs_readings: 'Readings',
  graph_tabs_prediction: 'Prediction',
  graph_tabs_average: 'Average',
  graph_tabs_previous: 'Previous',
  graph_column_sensor: 'Sensor',
  graph_column_index: 'Index',
  graph_column_device: 'Device',
  graph_column_device_name: 'Device Name',
  graph_column_date: 'Date',
  graph_column_readings: 'Readings',
  graph_column_previous_readings: 'Previous Readings',
  graph_column_delta: 'Delta',
  /*
   * |===============|
   * | Settings page |
   * |===============|
   */
  settings_page_header: 'Settings',
  settings_submit_success_msg: 'Settings saved',
  settings_submit_error_msg: 'Settings could not be saved',
  settings_input_invalid_format_error_msg: 'Invalid data format',
  settings_certificate_not_found_error: 'No certificate was found on this file path',
  settings_add_button: 'Add',
  settings_submit_button: 'Submit',
  settings_input_key_label: 'Key',
  settings_input_type_label: 'Type',
  settings_input_value_label: 'Value',
  settings_select_type_string: 'String',
  settings_select_type_number: 'Number',
  settings_select_type_boolean: 'Boolean',
  settings_select_type_object: 'Object',
  settings_logo_label: 'Logo',
  settings_dashboard_layout_label: 'Dashboard layout',
  settings_certificate_label: 'Certificate path',
  settings_device_panel_title: 'Device Panel Title',
  settings_sensor_panel_title: 'Sensor Panel Title',
  settings_header_home_label: 'Header Home Label (Portal)',
  settings_header_dashboard_label: 'Header Dashboard Label (Portal)',
  settings_footer_main_label: 'Footer Main Label (Portal)',
  settings_footer_link_label: 'Footer Link Label (Portal)',
  settings_footer_link_url: 'Footer Link URL (Portal)',
  settings_alarm_notification_display_period: 'Alarm Notification Display Period (hours) (Portal)',

  /* |===========|
   * | New Graph |
   * |===========|
   */

  //page
  new_graph_add_device_group_modal_title: 'Device Group Sensors "Every" Configuration',
  new_graph_add_device_group_modal_device_group: 'Device group: ',
  new_graph_add_device_group_modal_sensors_to_add: 'Sensors to add: ',
  new_graph_add_device_group_modal_every_selector_mode_per_device: 'Per Device',
  new_graph_add_device_group_modal_every_selector_mode_per_sensor: 'Per Sensor',
  new_graph_add_device_group_modal_sensor_limit_message:
    'You cannot exceed the graph limit of {{graphSensorsLimit}} sensors!',
  new_graph_add_device_group_modal_confirm_button: 'Confirm',
  new_graph_add_device_group_modal_cancel_button: 'Cancel',
  new_graph_select_sensor_heading: 'Select Sensor',
  new_graph_date_range_selection: 'Date Range Selection',
  new_graph_selected_sensors_heading: 'Selected Sensors',
  new_graph_selected_sensors_remove_all_tooltip: 'Clear all',
  new_graph_select_company_message: 'Please select a company',
  new_graph_show_sensors_statistics_label: 'Show Statistics',

  //sensor selection
  new_graph_device_group_label: 'Device Group',
  new_graph_device_label: 'Device',
  new_graph_sensor_label: 'Sensor',

  //date range selection
  new_graph_average_days_label: 'Average days',
  new_graph_time_series_prediction_period_label: 'Period',
  new_graph_auto_refresh_label: 'Refresh',
  new_graph_period_picker_heading: 'Readings',
  new_graph_average_days_heading: 'Average',
  new_graph_time_series_prediction_period_heading: 'Time Series Prediction',
  new_graph_none_option: 'None',
  new_graph_day_option_one: '{{count}} day',
  new_graph_day_option_other: '{{count}} days',
  new_graph_week_option_one: '{{count}} week',
  new_graph_week_option_other: '{{count}} weeks',
  new_graph_month_option_one: '{{count}} month',
  new_graph_month_option_other: '{{count}} months',
  new_graph_year_option_one: '{{count}} year',
  new_graph_year_option_other: '{{count}} years',

  //tooltip
  new_graph_current_value_name: 'Current',
  new_graph_average_value_name: 'Average',
  new_graph_prediction_value_name: 'Prediction',

  //error
  new_graph_fetch_device_groups_error: 'Could not load device groups for company.',
  new_graph_fetch_devices_error: 'Could not load devices for company.',
  new_graph_fetch_device_config_error: 'Could not load the config of the selected device.',
  new_graph_fetch_device_ports_config_error: 'Could not load ports for the selected device.',
  new_graph_fetch_device_flow_error: 'Could not load flow for {{deviceName}}.',
  new_graph_fetch_device_flow_avg_error: 'Could not load average flow for {{deviceName}}.',
  new_graph_fetch_device_readings_error: 'Could not load readings for {{deviceName}}.',
  new_graph_fetch_device_readings_avg_error: 'Could not load average readings for {{deviceName}}.',
  new_graph_fetch_device_on_off_alarms_error: 'Could not load on/off alarms for {{deviceName}}.',
  new_graph_fetch_device_prediction_error: 'Could not load prediction for {{deviceName}}.',
  new_graph_sensor_already_in_state_error:
    'This specific sensor has already been added to the list.',

  /* |================|
   * | Billing Report |
   * |================|
   */
  billing_report_header: 'Billing Report',
  billing_report_btn_generate: 'Generate',
  billing_report_device_number: 'Device Number',
  billing_report_first_reading_date: 'First Reading Date',
  billing_report_last_reading_date: 'Last Reading Date',
  billing_report_readings: 'Readings',
  billing_report_battery: 'Battery',
  billing_report_signal: 'Signal',
  billing_report_total: 'Total',

  /* |================|
   * | Profile Page   |
   * |================|
   */
  profile_page_change_logo: 'Change logo',
  profile_page_logo_formats_message: 'Acceptable formats: PNG, JPEG',
  profile_page_name: 'Name',
  profile_page_email: 'Email',
  profile_page_language: 'Language',
  profile_page_password: 'Password',
  profile_page_change_language: 'Change language',
  profile_page_update_license: 'Update License',
  profile_page_change_password: 'Change password',
  profile_page_generate: 'Generate',
  profile_page_header: 'Profile',
  profile_page_user_info_label: 'User Info',
  profile_page_license_label: 'License ',
  profile_page_license_type: 'License Type',
  profile_page_valid_from: 'Valid from',
  profile_page_valid_to: 'Valid To',
  profile_page_max_readings: 'Max Readings',
  profile_page_current_count: 'Current count',
  profile_page_first_reading: 'First Reading',
  profile_page_last_reading: 'Last Reading',
  profile_page_license_status: 'Status',
  profile_page_renew_license_button: 'Renew License',
  profile_page_deactivate_license_button: 'Deactivate License',
  profile_page_valid_license: 'Valid License',
  profile_page_invalid_license: 'Invalid License',
  profile_page_deactivate_license_success_message: 'License deactivated.',
  profile_page_deactivate_license_error_message: 'Could not deactivate license.',
  profile_page_deactivate_modal_title: 'License deactivation',
  profile_page_confirm_button: 'Confirm',
  profile_page_cancel_button: 'Cancel',
  profile_page_deactivate_license_modal_description:
    'Are you sure you want to deactivate this license?',
  profile_page_activate_license_modal_description:
    'Are you sure you want to activate this license?',
  profile_page_activate_modal_title: 'License Activation',
  profile_page_license_per_device: 'per device',
  profile_page_license_per_reading: 'per reading',
  profile_page_license_per_sensor: 'per sensor',
  profile_page_license_per_poc: 'per proof of concept (POC)',
  profile_page_license_per_remote_node: 'per remote node',
  profile_page_license_partial: 'partial',
  profile_page_logo_file_format_error: 'Invalid file type. Please upload an image.',
  profile_page_logo_size_error: 'File size exceeds 1MB. Please upload a smaller image.',
  profile_page_version_info: 'Version Info:',

  logo_error_message: 'Could not load company logo',

  profile_page_country: 'Country',
  profile_page_timezone: 'Timezone',
  profile_page_address: 'Address',
  profile_page_city: 'City',
  profile_page_municipality: 'Municipality',
  profile_page_area: 'Area',
  profile_page_zip_code: 'ZIP Code',
  profile_page_vat_number: 'VAT Number',
  profile_page_edit: 'Edit Account',
  profile_page_update_account_success: 'Account updated successfully',
  profile_page_license_info: 'License Info',

  /* |===============|
   * | Utility Rates |
   * |===============|
   */
  util_rates: 'Utility Rates',

  // table
  util_rates_name: 'Name',
  util_rates_price: 'Price',
  util_rates_currency: 'Currency',
  util_rates_unit: 'Unit',
  util_rates_private: 'Private',
  util_rate_company: 'Company',
  util_rate_created: 'Created',
  util_rates_updated: 'Updated',
  util_rates_actions: 'Actions',
  util_rates_tooltip_inspect: 'Inspect',

  // modals
  util_rates_add_modal_header: 'Add Utility Rate',
  util_rates_edit_modal_header: 'Edit {{name}}',
  util_rates_delete_modal_header: 'Delete {{name}}',
  util_rates_delete_modal_body: 'This will permanently delete the chosen utility rate',
  util_rates_confirm: 'Confirm',
  util_rates_cancel: 'Cancel',
  util_rates_loading: 'Loading...',
  util_rates_error: 'Error',
  util_rates_error_name_exists: 'Utility rate with this name already exists',

  // errors
  util_rate_fetch_company_error: 'Could not load companies',
  util_rate_fetch_util_rates_error: 'Could not load utility rates',

  // footer
  footer_powered_by: 'Powered by',
  util_rates_name_required: 'Name is required',
  util_rates_price_required: 'Price is required',

  /*
   * |=======|
   * | Icons |
   * |=======|
   */
  icon_type: 'Type',
  icon_type_device: 'Device',
  icon_type_sensor: 'Sensor',

  icon_scope: 'Scope',
  icon_scope_global: 'Global',
  icon_scope_company: 'Company',
  icon_scope_device: 'Device',
  icon_scope_sensor: 'Sensor',

  icon_input_device: 'Device',
  icon_input_sensor: 'Sensor',
  icon_input_name: 'Name',
  icon_input_company: 'Company',

  icon_btn_add_icon: 'Add Icon',
  icon_btn_upload_file: 'Upload File',
  icon_btn_cancel: 'Cancel',
  icon_btn_save: 'Submit',

  icon_table_name: 'Name',
  icon_table_scope: 'Scope',
  icon_table_icon: 'Icon',
  icon_table_actions: 'Actions',

  // errors
  icon_error_update_icon: 'Could not update icon',
  icon_error_unsupported_file_type: 'Invalid file type. Only JPEG, PNG, and SVG are allowed.',
  icon_error_error_occured: 'An error occurred',
  icon_error_enter_name: 'Please enter a name',
  icon_error_enter_icon: 'Please upload an icon',
  icon_error_select_company: 'Please select a company',
  icon_error_select_device: 'Please select a device',
  icon_error_select_sensor: 'Please select a sensor',

  /*
   * |=========================|
   * | Customer Billing Report |
   * |=========================|
   */
  customer_billing_report_account_id_table_header: 'Account Id',
  customer_billing_report_description_table_header: 'Description',
  customer_billing_report_customer_name_table_header: 'Customer Name',
  customer_billing_report_street_table_header: 'Street',
  customer_billing_report_quarter_table_header: 'Quarter',
  customer_billing_report_last_reading_table_header: 'Last Reading',
  customer_billing_report_last_reading_date_table_header: 'Last Reading Date',
  customer_billing_report_device_number_table_header: 'Device Number',
  customer_billing_report_last_config_date_table_header: 'Last Configuration Date',
  customer_billing_report_billing_group_table_header: 'Billing Group',
  customer_billing_report_meter_serial_table_header: 'Meter Serial',
  customer_billing_report_diameter_table_header: 'Diameter',
  customer_billing_report_meter_brand_table_header: 'Meter Brand',
  customer_billing_report_meter_model_table_header: 'Meter Model',
  customer_billing_report_default_error: 'An error occured while generating the report',
  customer_billing_report_contract_number_table_header: 'Contract number',
  customer_billing_report_device_name_table_header: 'Device name',
  customer_billing_report_sensor_name_table_header: 'Sensor name',

  //license
  license_license_warning: 'License Warning',
  license_license_warning_per_reading_over_limit_singular: 'of type per reading exceeds the limit',
  license_license_warning_expire_singular: 'expiring soon',
  license_license_warning_per_reading_over_limit_plural: 'of type per readings exceed the limit',
  license_license_warning_expire_plural: 'expiring soon',
  license_max_count_error: 'Max count cannot be 0',

  license_header: 'License',
  license_table_company_name: 'Company Name',
  license_table_license_type: 'License Type',
  license_table_max_count: 'Max Count',
  license_table_valid_from: 'Valid From',
  license_table_valid_to: 'Valid To',
  license_table_first_reading_date: 'First Reading Date',
  license_table_last_reading_date: 'Last Reading Date',
  license_table_active: 'Active',
  license_table_valid: 'Valid',
  license_table_yes: 'Yes',
  license_table_no: 'No',
  license_table_actions: 'Actions',

  license_warning_access_hold: 'Account Access on Hold',
  license_warning_access_hold_description:
    'Your access to the platform has been temporarily suspended due to unresolved payment. We encourage you to reach out to our support or connect with your designated representative to assist you in resolving this matter.',
  license_warning_no_valid_license: 'No valid license',
  license_warning_no_valid_license_description:
    'We could not find valid license associated with this account. Please reach out to your designated representative or contact our support for assistance.',
  license_warning_devices_count_limit_reached: 'Maximum Devices Reached',
  license_warning_devices_count_limit_reached_description:
    'You have reached the maximum devices you can link to the account from the license. If you want to add more, please contact your designated representative or our support for assistance.',
  license_warning_generic_title: 'No License',
  license_warning_generic_description: 'Can not proceed due to license issue.',
  license_info_renew_button: 'Renew License',
  license_info_deactivate_button: 'Deactivate License',
  license_info_renew_modal_button: 'Renew',
  license_info_used_readings: 'Used Readings',
  license_info_readings_left: 'Readings left',
  license_renew_license_button: 'Renew',
  license_deactivate_license_button: 'Deactivate',
  license_select_company_warning_message: 'Select a company to proceed',
  license_add_button: 'Add License',
  license_add_success_message: 'License created successfully',
  license_add_error_message: 'An error occured during the process',
  license_available_licenses: 'Available licenses',
  license_max_devices: 'Max Devices',
  license_max_readings: 'Max Readings',
  license_max_sensors: 'Max Sensors',
  license_company: 'Company',
  license_deactivate_warning: "This will suspend users' access to the platform",
  license_activate_warning: "This will restore users' access to the platform",
  license_activate_license_button: 'Activate',
  license_available_licenses: 'Available licenses',
  license_add_license: 'Add License',
  license_status_active: 'Active',
  license_status_inactive: 'Inactive',
  license_info_activate_button: 'Activate license',
  license_info_used_devices: 'Used devices',
  license_info_devices_left: 'Devices left',
  license_error_page_access_restricted: 'Access Restricted',
  license_valid_license_existing: 'You have a valid license of this type already!',
  license_conflicting_license_date: 'There is a valid license within the same period',

  //Password
  change_password: 'Change Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  customer_info_saved: 'Customer Info Saved',

  /*
   * |=========================|
   * |     Reset Password      |
   * |=========================|
   */
  reset_password_check_email_for_reset_link: 'Check your email for reset password link',
  reset_password_default_error_message: 'There was an error trying to reset your password',
  reset_password_change_password_label: 'Change password',
  reset_password_send_btn: 'Send',

  /*
   * |==============================|
   * |  Sensors and Network Config  |
   * |==============================|
   */

  //headers
  sensors_and_network_config_network_config: 'Device Network Configuration',
  sensors_and_network_config_device_details: 'Device Details',
  sensors_and_network_config_add_sensor: 'Add Sensor',

  //messages
  sensors_and_network_save_success: 'Config was saved successfully.',
  sensors_and_network_save_error: 'There was a problem saving the config.',
  sensors_and_network_formula_validation_default_error: 'Formula validation failed.',
  sensors_and_network_config_required_field_error: 'This field is required',

  //labels
  sensors_and_network_config_sensor_index: 'Sensor index',

  //buttons
  sensors_and_network_config_reset_btn: 'Reset',

  //tooltips
  config_tooltip_select_sensor_icon: 'Select sensor icon',
  config_tooltip_sensor_name: 'Sensor name',
  config_tooltip_enable_disable_sensor: 'Enable/Disable sensor',
  config_tooltip_reset_sensor: 'Reset new changes for this sensor',
  config_tooltip_reset_all_fields: 'Reset all fields to last saved values',
  config_tooltip_delete_sensor: 'Delete sensor',
};

export default i18n_en;
