import React, { FC } from 'react';
import Header from '../../components/header';
import { Link } from 'react-router-dom';
import DocumentationSection from './DocumentationSection';

const Documentation: FC<DocumentationProps> = ({}: DocumentationProps) => {
  const platformDocs: Link[] = [
    { title: 'Sign up/Sign in and Main menu', path: 'sign-in-and-main-menu.pdf' },
    { title: 'ThingsLog account management', path: 'thingslog-account-management.pdf' },
    { title: 'ThingsLog user management', path: 'thingslog-user-management.pdf' },
    {
      title: 'ThingsLog data loggers configuration',
      path: 'thingslog-data-loggers-configuration.pdf',
    },
    {
      title: 'Configuring and reviewing ThingsLog alarms and value range events',
      path: 'configuring-and-reviewing-things-log-alarms-and-value-range-events.pdf',
    },
    {
      title: 'Port & Sensor parameter builder for modbus slave devices',
      path: 'port-sensor-parameter-builder-for-modbus-slave-devices.pdf',
    },
    {
      title: 'Setting up tasmota devices to work with ThingsLog platform',
      path: 'setting-up-tasmota-devices-to-work-with-thingslog-platform.pdf',
    },
    {
      title: 'Setting up Modbus slave devices to work with ThingsLog controllers',
      path: 'setting-up-modbus-slave-devices-to-work-with-thingslog-controllers.pdf',
    },
    { title: 'Licenses', path: 'licenses.pdf' },
    {
      title: 'Old user manual',
      path: 'tl-en.pdf',
    },
  ];

  const dataLoggerDocs: Link[] = [
    { title: 'Thingslog data logger user manual', path: 'LPMDL-110x-EN_v1.10.2024.pdf' },
    { title: 'Thingslog data logger command guide', path: 'LPMDL_110x_command_guide-EN-4.9.6.pdf' },
  ];

  const communicationProtocolDocs: Link[] = [
    {
      title: 'Open Lora data protocol',
      path: 'LPMDL-110X-LoRa-open-data-protocol.pdf',
    },
    {
      title: 'MQTT open data protocol',
      path: 'LPMDL-110x-MQTT-open-data-protocol.pdf',
    },
  ];
  return (
    <Header>
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-2xl font-bold text-black text-center">Thingslog Documentation</h1>
      </div>
      <div className="w-full max-w-fit mx-auto space-y-8">
        <DocumentationSection title="Platform Documentation" docs={platformDocs} />
        <DocumentationSection title="Data Logger Documentation" docs={dataLoggerDocs} />
        <DocumentationSection
          title="Communication Protocol Documentation"
          docs={communicationProtocolDocs}
        />
      </div>
    </Header>
  );
};

export interface DocumentationProps {}

export interface Link {
  title: string;
  path: string;
}

export default Documentation;
